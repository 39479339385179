import { Component, Input } from '@angular/core';
import { ImmigrationCompany } from 'src/app/shared/models/ImmigrationCompany';
import { ImmiexpertCompanyService } from 'src/app/shared/services/immiexpert-company.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationModel } from 'src/app/shared/models/notification.model';

@Component({
  selector: '[app-immigration-admin-view-row]',
  templateUrl: './immigration-admin-view-row.component.html',
  styleUrls: ['./immigration-admin-view-row.component.css']
})
export class ImmigrationAdminViewRowComponent {

  @Input()
  company: ImmigrationCompany;

  commentValue = null;
  addsearchesValue: string = null;
  searchExpiration: string = null;
  statusChangedToInactive: boolean;
  isHistoryVisible = false;

  constructor(
    private notifyService: NotificationService,
    private immiExpertCompanyService: ImmiexpertCompanyService) { }

  onChangeStatus(event: any) {
    this.company.status = Number(event.target.value);
    // Reset all fields if status is changed to Inactive
    if (Number(event.target.value) === 0) {
      this.commentValue = null;
      this.addsearchesValue = null;
      this.searchExpiration = null;
      this.statusChangedToInactive = true;
    } else {
      this.statusChangedToInactive = false;
    }
  }

  onChangeAddSearches(event: any) {
    this.addsearchesValue = event.target.value;
  }

  onChangeAddExpiration(event: any) {
    this.searchExpiration = event.target.value;
  }

  isCompanyDisabled(status: number) {
    return status !== 1;
  }

  disableSave() {
    if (this.company.status === 1) {
      return this.commentValue === null || this.commentValue === '' || this.addsearchesValue === null || this.searchExpiration === null;
    } else {
        return !this.statusChangedToInactive;
    }
  }

  cancel() {
    this.commentValue = null;
    this.addsearchesValue = null;
    this.searchExpiration = null;
  }

  save() {
    let immiCompany: ImmigrationCompany = null;
    let comment: string;
    let requestAmount: string;

    if (this.company.status === 1) {
      comment = this.commentValue;
      requestAmount = this.addsearchesValue === 'Unlimited' ?
        null : this.addsearchesValue;

      immiCompany = {
        id: this.company.id,
        name: this.company.name,
        status: this.company.status,
        searchCap: this.addsearchesValue === 'Unlimited' ? null :
          this.company.searchCap + Number(this.addsearchesValue),
      };
    } else if (this.company.status === 0) {
      immiCompany = {
        id: this.company.id,
        name: this.company.name,
        status: this.company.status,
      };
    }

      this.immiExpertCompanyService.updateCompany(this.company.id, immiCompany, comment, requestAmount, this.searchExpiration)
        .subscribe(c => {
          this.company = c;
          this.statusChangedToInactive = false;
          this.commentValue = null;
          this.addsearchesValue = null;
          this.searchExpiration = null;

          this.notifyService.notify(
            new NotificationModel({
              level: 'success',
              isPopup: false,
              autoClose: 3000,
              msg: `Client ${this.company.name} saved succesfully.`
            })
          );
        }, error => {
          this.notifyService.notify(
            new NotificationModel({
              level: 'error',
              isPopup: false,
              msg: error.error || error.message
            }));
        });
    }
  }
