import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { APP_CONFIG } from 'access-common';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  layoutCssClass = '';
  unauthorized: boolean;
  currentYear = new Date().getFullYear();

  constructor(@Inject(APP_CONFIG) private config,
              private titleService: Title,
              private snackBar: MatSnackBar) {
    this.preventDefaultDragNDropBehavior();
    this.titleService.setTitle(config['title'] || 'KPMG Move');
    if (!JSON.parse(localStorage.getItem('browserSuggestionMsgSeen'))) {
      this.showSuggestedBrowsersMessage();
    }
  }

  preventDefaultDragNDropBehavior() {
    window.addEventListener('dragover', e => {
      if (e) { e.preventDefault(); }
    }, false);
    window.addEventListener('drop', e => {
      if (e) { e.preventDefault(); }
    }, false);
  }

  isBrowser(browserName) {
    if (!navigator || !navigator.userAgent) { return; }
    return navigator.userAgent.toLowerCase().indexOf(browserName) > -1;
  }

  showSuggestedBrowsersMessage() {
    const isSuggestedBrowser = this.isBrowser('chrome') || this.isBrowser('firefox');
    if (isSuggestedBrowser) {
      return;
    }
    this.snackBar.open('Immigration Expert is best viewed with Google Chrome or Mozilla Firefox web browsers. We strongly recommend using either of these if possible.', 'OK', {
      panelClass: 'im-snackbar'
    });
    localStorage.setItem('browserSuggestionMsgSeen', 'true');
  }
}
