interface NotificationConfig {
  level: NotificationLevel;
  isPopup: boolean;
  autoClose?: boolean | number;
  translationKey?: string;
  msg: string | string[];
  headline?: string;
}

export class NotificationModel {
  config: NotificationConfig;
  constructor(config: NotificationConfig) {
    this.config = config;
  }
}

export type NotificationLevel = 'success' | 'info' | 'warn' | 'error';

/*
interface NotificationConfig {
  level: NotificationLevel;
  isPopup: boolean;
  autoClose?: boolean | number;
  msg: NotificationParagraph | NotificationParagraph[];
}

export class NotificationParagraph {
  text: string;
  isTranslationKey: boolean;
  constructor (paragraph: string) {
    this.text = paragraph;
    this.isTranslationKey = (paragraph.charAt(0) === '{' && paragraph.charAt(1) === '{' && paragraph.charAt((paragraph.length - 1))
     === '}' && paragraph.charAt((paragraph.length - 2)) === '}' && paragraph.indexOf('translate') > 0);
  }
}

*/
