import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SessionService } from '../shared/services/session.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../shared/services/notification.service';
import { NotificationModel } from '../shared/models/notification.model';
import { SessionUserJSON } from 'access-common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Configuration } from '../shared/services/configuration';
import { ConfigurationKeys } from '../shared/constants';

@Component({
  selector: 'app-immigration-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.css']
})
export class ImmigrationTosComponent implements OnInit {
  accepted = undefined; // Default to undefined so async init does not flash the form in the page
  user: SessionUserJSON;
  session: any;

  form: FormGroup;
  immigrationTos: SafeHtml;
  immigrationTosFileName: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private configuration: Configuration
  ) {
    this.form = this.createForm();
  }

  setAcceptedTos(tos) {
    const basePath = this.configuration.getConfig(
      ConfigurationKeys.IMMIGRATION_API_BASE_PATH
    );
    const url = `${basePath}/acceptedTerms`;
    const data = {
      acceptedTermsOfService: tos
    };
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.httpClient.post<string>(url, data, { headers: headers });
  }

  getCurrentTosFileName() {
    return this.httpClient.get('assets/immigrationTos.json');
  }

  ngOnInit() {
    this.getCurrentTosFileName().subscribe((fileDetails: object) => {
      this.immigrationTosFileName = fileDetails['filename'];
      if (!this.immigrationTosFileName) {
        this.notificationService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: false,
            msg: 'Error occurred while loading Terms and Conditions'
          })
        );
        return;
      }
      this.httpClient
        .get('assets/' + this.immigrationTosFileName, {
          responseType: 'text'
        })
        .subscribe(data => {
          this.immigrationTos = this.sanitizer.bypassSecurityTrustHtml(data);
        });
      this.sessionService.getActiveSession().subscribe(session => {
        this.session = session;
      });
    });
  }

  submit() {
    if (this.form.valid) {
      this.setAcceptedTos(this.immigrationTosFileName).subscribe(userInfo => {
        const targetRoute = localStorage.getItem('targetRoute');
        if (targetRoute) {
          this.router.navigateByUrl(targetRoute);
          localStorage.removeItem('targetRoute');
        } else {
          this.router.navigateByUrl('../search');
        }
      });
    } else {
      this.notificationService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: false,
          msg:
            'KPMG Global Immigration Expert User Terms and Conditions must be accepted prior to accessing the site'
        })
      );
    }
  }

  private createForm() {
    return this.formBuilder.group({
      approve: [false, Validators.required]
    });
  }
}
