import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ConfigurationHolder } from './app/configuration.holder';
import { APP_CONFIG } from 'access-common';
import 'isomorphic-fetch';

if (environment.production) {
  enableProdMode();
}

fetch('config/config.json')
  .then(response => response.json())
  .then(response => {
    ConfigurationHolder.configuration = response;
    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: response }
    ]).bootstrapModule(AppModule);
  })
  .catch(error => {
    if (console) {
      console.error(error);
    }
  });
