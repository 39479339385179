import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationModel } from '../models/notification.model';

@Injectable()
export class NotificationService {
  private notificationSubject = new BehaviorSubject<NotificationModel>(
    undefined
  );

  public notification$: Observable<
    NotificationModel
  > = this.notificationSubject.asObservable();

  constructor() {}

  notify(notification: NotificationModel) {
    this.notificationSubject.next(notification);
  }

  clearNotifications() {
    this.notificationSubject.next(undefined);
  }
}
