import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ImmigrationCompanySearchRequest } from "src/app/shared/models/ImmigrationCompany";

@Component({
  selector: '[app-immigration-admin-view-search-package-row]',
  templateUrl: './immigration-admin-view-search-package-row.component.html',
  styleUrls: ['./immigration-admin-view-search-package-row.component.css', '../immigration-admin-view-row.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImmigrationAdminViewSearchPackageRowComponent {
  @Input()
  searchPackage: ImmigrationCompanySearchRequest;

  @Input()
  packageIndex: number;

  parseExpiration(expiration: string) {
    if (!expiration) return 'No expiration';
    if (Date.parse(expiration) < Date.now()) return 'Expired';

    const expirationDate = new Date(expiration);

    return `${expirationDate.getDate()}.${expirationDate.getMonth() + 1}.${expirationDate.getFullYear()}`;
  }
}
