import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CompanyJSON } from 'access-common';
import { CompanyService as CompanyApi } from 'access-common';
import {ImmigrationCompany} from '../models/ImmigrationCompany';

@Injectable()
export class CompanyService {
  private subject = new BehaviorSubject<CompanyJSON & ImmigrationCompany>(undefined);
  private selectedCompany$ = this.subject.asObservable();

  constructor(
    private companyApi: CompanyApi
  ) {}

  /**
   * Get company from back-end
   */
  getCompany(id: string) {
    return this.companyApi.getCompanyUsingGET(id);
  }

  getSelectedCompany(): Observable<CompanyJSON & ImmigrationCompany> {
    return this.selectedCompany$;
  }

  deselectCompany(): void {
    localStorage.removeItem('selectedCompany');
    this.subject.next(undefined);
  }

  selectCompany(company: CompanyJSON & ImmigrationCompany) {
    localStorage.setItem('selectedCompany', JSON.stringify(company.id));
    this.subject.next(company);
  }
}
