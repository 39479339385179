import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  filter as _filter,
  findIndex as _findIndex,
  flatMapDeep as _flatMapDeep,
  takeWhile as _takeWhile } from 'lodash';
import { ImmigrationCountry } from '../../shared/models/ImmigrationCountry';
import { ImmigrationGeneralModel } from '../../shared/models/ImmigrationGeneralModel';
import { ImmigrationQuestionService } from '../../shared/services/ImmigrationQuestion.service';
import { CountryService } from '../../shared/services/country.service';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NextQuestionResponse } from '../../shared/models/next-question-response';
import { b64DecodeUnicode } from '../../shared/base64';
import { animateIn, animateOut } from '../../shared/animations/animations';

export interface StateWithQuestion {
  state: any[];
  result: NextQuestionResponse;
}

@Component({
  selector: 'app-immigration-extra-questions',
  templateUrl: './immigration-extra-questions.component.html',
  styleUrls: ['./immigration-extra-questions.component.css'],
  animations: [
    animateIn,
    animateOut
  ]
})
export class ImmigrationExtraQuestionsComponent implements OnInit {
  public countries: ImmigrationCountry[];
  public citizenship: ImmigrationGeneralModel;
  public destination: ImmigrationGeneralModel;
  public countryOfResidence: ImmigrationCountry;
  public eqActions: Array<any> = [];
  public eqQuestions: Array<any> = [];
  public answer: Object = {};
  public queryParams: Object = {};
  public selectedActivity: Object = { name: '', value: '' };
  public residenceIsSameAsCitizenship: Boolean = true;
  public extraQuestionsDataLoaded = false;
  public nextQuestionLoading = false;
  public countryOfResidenceSet = false;
  public selectedCountryOfResidence = true;
  private currentUrlPath: String
          = location.pathname.replace('/apps/immigration-expert/', '');
  private lastState: {};

  constructor(
    private countryService: CountryService,
    private immigrationQuestionService: ImmigrationQuestionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((queryParams: any) => {
      Object.keys(queryParams).forEach(param => {
        this.queryParams[param] = queryParams[param];
      });
    });
  }

  isPossible(question, value) {
    if (question.type === 'COUNTRY_LIST') {
      return question.possibleValues.findIndex(element => element.value === value) !== -1;
    } else if (question.type === 'LIST') {
      return question.possibleValues.indexOf(value) !== -1;
    } else if (question.type === 'TREE') {
      if (value) {
        // split by ;
        const split = value.split(';');
        // Take the right half of ; if possible
        const val = (split.length > 1 ? split[1] : split[0]).trim();
        // get childs instead of toplevel
        const values = _flatMapDeep(question.possibleValues.children, (e) => e.children).map(e => e.value);
        return values.includes(val);
      }
    }

    return false;
  }

  getState(_initialState, _lastState): Observable<StateWithQuestion> {
    const state = _initialState;
    const lastState = _lastState || {};
    return this.immigrationQuestionService.nextSection(this.queryParams['CITIZENSHIP'], this.queryParams['DESTINATION'], state).pipe(
      switchMap(qres => {
        // Exit early if no more questions left
        if (qres.result.actions.length !== 1) {
          return of(qres);
        }

        const questions = qres.result.questions;
        const question = questions[questions.length - 1];

        // if we have already value
        if (question.selectedValue) {
          return of(qres);
        }
        // if we have a param for this question
        if (lastState[question.key]) {
          const lastSearchValue = lastState[question.key];
          if (this.isPossible(question, lastSearchValue)) {
            state.push({ key: question.key, value: lastSearchValue });
            return this.getState(state, lastState);
          }
        }
        // If no matches, return the got result
        return of(qres);
    }));

  }

  // Component init event
  ngOnInit() {
    this.countryService.getAllCountries().subscribe(res => {
        this.countries = res;

        if (this.queryParams['state']) {
          this.lastState = JSON.parse(b64DecodeUnicode(this.queryParams['state']));
          this.getState([], this.lastState).subscribe(qres => {
            this.setEqSiteData(qres.result.questions, qres.result.actions);
            const citizenship = this.queryParams['CITIZENSHIP'];
            const destination = this.queryParams['DESTINATION'];
            this.queryParams = {};
            this.queryParams['CITIZENSHIP'] = citizenship;
            this.queryParams['DESTINATION'] = destination;

            qres.state.forEach(q => this.queryParams[q.key] = q.value);

            this.extraQuestionsDataLoaded = true;
          });
        } else {
          this.immigrationQuestionService.nextSection(this.queryParams['CITIZENSHIP'], this.queryParams['DESTINATION'], []).subscribe(qres => {
            this.setEqSiteData(qres.result.questions, qres.result.actions);
            this.setAnswersBasedOnQueryParams(qres.result.questions, this.queryParams);
            this.extraQuestionsDataLoaded = true;
          }, (error: Error) => {
            console.log(error);
            this.extraQuestionsDataLoaded = true;
          });
        }
    });
  }

  private goToSummary () {
    this.router.navigate(['../summary'], {
      relativeTo: this.route,
      queryParams: this.queryParams
    });
  }

  private setEqSiteData(questions, actions) {
    if (questions && questions.length < 1) {
      this.goToSummary();
    }
    this.eqQuestions = questions;
    this.eqActions = actions;
  }

  private isEmptySpec(property) {
    return !property || !property.name || property.name.length < 2;
  }

  private isEmptyObject(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

    private removeAnswersAfterQuestion(questionKey) {
      const numberOfQuestionsAnswered = Object.keys(this.answer).length;
      if (numberOfQuestionsAnswered < 2) {
        return;
      }
      const indexOfQuestion = Object.keys(this.answer).indexOf(questionKey);
      if (indexOfQuestion < numberOfQuestionsAnswered) {
        for (let i = indexOfQuestion; i < numberOfQuestionsAnswered; i++) {
          delete this.answer[Object.keys(this.answer)[i]];
        }
      }
    }

  // Gets country object with country name
  getCountryWithName(name) {
    if (!name) {
      return;
    }
    return _filter(
      this.countries,
      c => c.name && c.name.toLowerCase() === name.toLowerCase()
    )[0];
  }

  // Gets country object with two character locale
  getCountryWithLocale(value) {
    if (!value) {
      return;
    }
    return _filter(this.countries, c => c.value && c.value === value)[0];
  }

  // Sets answers to input fields based on query parameters
  setAnswersBasedOnQueryParams(questions, params) {
    this.destination = this.getCountryWithLocale(params['DESTINATION']);
    this.citizenship = this.getCountryWithLocale(params['CITIZENSHIP']);
    Object.keys(this.queryParams).forEach((key: string) => {
      if (key === 'DESTINATION' || key === 'CITIZENSHIP') {
        return;
      }
      this.answer[key] = this.queryParams[key];
      const qIdx = _findIndex(this.eqQuestions, q => {
        return q['key'] === key;
      });
      if (qIdx !== -1) {
        this.getValueFromChild({name: this.eqQuestions[qIdx].displayName, value: this.queryParams[key]}, key);
      }
    });
  }

  toggleCorSameAsCitizenship(isCitizenship) {
    const citizenship = this.getCountryWithLocale(this.queryParams['CITIZENSHIP']);
    this.eqQuestions.forEach(q => {
      if (q.key === 'RESIDENCE') {
        q.selectedValue = isCitizenship ? citizenship.name : undefined;
      }
    });
    this.countryOfResidence = isCitizenship ? citizenship : null;
    this.countryOfResidenceSet = isCitizenship;
    this.selectedCountryOfResidence = isCitizenship;
    this.updateQueryParam('RESIDENCE', isCitizenship ? this.countryOfResidence.value : undefined);
    this.answer['RESIDENCE'] = isCitizenship ? citizenship.value : undefined;
    if (isCitizenship) {
      this.getNextQuestion({ key: 'RESIDENCE', value: citizenship.value });
    }
  }

  private convertToPayloadArray(params) {
    const payload = [];
    Object.keys(params).forEach(key => {
      payload.push({'key': key, 'value': params[key]});
    });
    return payload;
  }


  getPayloadPairFromQuestion(question) {
    if (question.type === 'COUNTRY_LIST') {
      return {
        key: question.key,
        value: question.possibleValues.find(e => e.name === question.selectedValue).value
      };
    }
    return { key: question.key, value: question.selectedValue };
  }

  // Loads next question
  getNextQuestion(changed: any) {
    // if changed element is before, get to state where questions after that are left out
    const untilChanged = _takeWhile(this.eqQuestions, question => question.key !== changed.key);
    // Convert payload from questions that have value set
    const payload = untilChanged.map(question => this.getPayloadPairFromQuestion(question));
    // Add changed element as last element, so query will go further
    payload.push(changed);

    this.nextQuestionLoading = true;

    this.getState(payload, this.lastState).subscribe(res => {
       this.nextQuestionLoading = false;
       this.eqActions = res.result.actions;
       this.eqQuestions = res.result.questions;
       const citizenship = this.queryParams['CITIZENSHIP'];
       const destination = this.queryParams['DESTINATION'];
       this.queryParams = {};
       this.queryParams['CITIZENSHIP'] = citizenship;
       this.queryParams['DESTINATION'] = destination;

       res.state.forEach(q => this.queryParams[q.key] = q.value);
    });
  }

  private getEncodedPathParams(params) {
    return '?' + Object.keys(params).map(function(key) {
      return key + '=' + encodeURI(params[key]);
    }).join('&');
  }

  // Updates query params to current location
  private updateQueryParam(key, value) {
    if (!key && !value) {
      return;
    }
    if (value) {
      this.queryParams[key] = value;
    } else {
      delete this.queryParams[key];
    }
    if (history && history.pushState) {
      history.pushState(null, null, this.currentUrlPath + this.getEncodedPathParams(this.queryParams));
    }
  }

  // Value setter event, triggered by input change
  getValueFromChild(event, key) {
    if (event['name'] && event ['value']) {
      this.updateQueryParam(key, event['value']);
      this.getNextQuestion({ key, value: event['value']});
      return;
    }
    let val: String;
    const srcEl = event.srcElement || event.target;

    if (srcEl && srcEl.value) {
      const valS = srcEl.value.split(':');
      const valIdx = valS[0];
      val = valS[1].substr(1);
    }
    this.updateQueryParam(key, val);
    this.getNextQuestion({ key, value: val });
  }

  // Sets country of residence
  setCountryOfResidence(e: boolean, changed: boolean) {
    if (e) {
      this.countryOfResidence = this.citizenship;
      this.selectedCountryOfResidence = true;
      this.residenceIsSameAsCitizenship = true;
      this.countryOfResidenceSet = true;
      this.getNextQuestion({ key: 'RESIDENCE', value: this.citizenship.value });
    } else {
      this.countryOfResidence = null;
      this.selectedCountryOfResidence = false;
      this.residenceIsSameAsCitizenship = false;
    }
  }

  // Common navigation event with url parameter
  goTo(url) {
    url = `..${url.split('?')[0]}`; // (remove query parameters from url passed by backend)
    this.router.navigate([url], {
      relativeTo: this.route,
      queryParams: this.queryParams
    });
  }
}

