import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationModel } from '../../shared/models/notification.model';
import { SearchService } from '../../shared/services/search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { filter as _filter } from 'lodash';
import { CountryService } from '../../shared/services/country.service';
import { b64EncodeUnicode } from '../../shared/base64';
import { ImmigrationLatestSearch } from '../../shared/models/ImmigrationLatestSearch';
import { animateIn, animateOut } from '../../shared/animations/animations';
import { navigateHome } from '../../shared/utils/navigate-home';
import { convertSearchParamsResponseToQuery } from '../../shared/utils/convert-response-to-params';

@Component({
  selector: 'app-immigration-latest-search',
  templateUrl: './immigration-latest-search.component.html',
  styleUrls: ['./immigration-latest-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    animateIn,
    animateOut
  ]
})
export class ImmigrationLatestSearchComponent implements OnInit {
  private _search = new BehaviorSubject<any>(null);
  public pathParams: Object = {};
  public showSearchParamsModal: boolean;

  constructor(
    private searchService: SearchService,
    private countryService: CountryService,
    private router: Router,
    private route: ActivatedRoute,
    private notifyService: NotificationService
  ) {}

  @Input()
  set search(value: ImmigrationLatestSearch) {
    this._search.next(value);
  }

  get search() {
    return this._search.getValue();
  }

  @Input() searchesSelectedToCompare: Array<ImmigrationLatestSearch>;

  @Input()
  showResultEnabled: boolean;

  @Output()
  removeSearchFromComparison: EventEmitter<ImmigrationLatestSearch> = new EventEmitter();

  @Output()
  addSearchToComparison: EventEmitter<ImmigrationLatestSearch> = new EventEmitter();

  ngOnInit() {
    if (this.searchesSelectedToCompare.some(i => i.id === this.search.id)) {
      this.search.isSelectedToCompare = true;
    }
  }

  onLatestSearchCardHoverEnter(search: ImmigrationLatestSearch) {
    search.isHovered = true;
  }

  onLatestSearchCardHoverLeave(search: ImmigrationLatestSearch) {
    search.isHovered = false;
  }

  removeFromCompare(search: ImmigrationLatestSearch) {
    search.isSelectedToCompare = false;
    this.removeSearchFromComparison.emit(search);
  }

  selectForCompare(search: ImmigrationLatestSearch) {
    if (this.isCompareSelectionEnabled(search)) {
      search.isSelectedToCompare = true;
      this.addSearchToComparison.emit(search);
    }
  }

  isCompareSelectionEnabled(search): boolean {
    return this.searchesSelectedToCompare.length < 2 && !this.searchesSelectedToCompare.some(item => item.id === search.id);
  }

  copySearchToNew(search) {
    let queryParamsToSearch;
    this.searchService.getSearchParameters(search.id).subscribe(params => {
      queryParamsToSearch = convertSearchParamsResponseToQuery(params);
      const state = b64EncodeUnicode(JSON.stringify(queryParamsToSearch));
      this.notifyService.notify(
        new NotificationModel({
          level: 'success',
          isPopup: false,
          autoClose: true,
          msg: 'Successfully copied the search parameters to new search'
        })
      );
      navigateHome(queryParamsToSearch, state, this.router);
    }, () => {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: 'Error fetching the search parameters. Please refresh the page and try again.'
        })
      );
    });
  }

  viewSearchParams(search) {
    this.showSearchParamsModal = true;
    this.searchService.getSearchParameters(search.id).subscribe(params => {
      this.search['params'] = params;
    }, () => {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: 'Error fetching the search parameters. Please refresh the page and try again.'
        })
      );
    });
  }

  getCountryWithLocale(value) {
    if (!value) {
      return;
    }
    const allCountries = this.getAllCountries();
    return _filter(
      allCountries,
      c => c.value && c.value.toLowerCase() === value.toLowerCase()
    )[0];
  }

  getAllCountries() {
    if (!JSON.parse(localStorage.getItem('allCountries'))) {
      this.countryService.getAllCountries().subscribe(countries => {
        localStorage.setItem('allCountries', JSON.stringify(countries));
      });
      return JSON.parse(localStorage.getItem('allCountries'));
    } else {
      return JSON.parse(localStorage.getItem('allCountries'));
    }
  }

  showResults(search) {
    this.searchService.getSearchParameters(search.id).subscribe(params => {
      params.forEach(p => {
        this.setParam(p.key, p.value);
      });
      this.router.navigate(['../results'], {
        relativeTo: this.route,
        queryParams: this.pathParams
      });
    }, () => {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: 'Error fetching the search parameters. Please refresh the page and try again.'
        })
      );
    });
  }

  private setParam(key, value) {
    this.pathParams[key] = value;
  }
}
