import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Configuration } from './configuration';
import { ImmigrationCountry, ImmigrationCountryDateAndCycleSetResponse, ImmigrationCountryUpdateReminderSetResponse } from '../models/ImmigrationCountry';
import { ConfigurationKeys } from '../constants';
import { Observable } from 'rxjs';
import { DecisionUpdateDto } from '../models/decision-update-dto';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  basePath = this.configuration.getConfig(
    ConfigurationKeys.IMMIGRATION_API_BASE_PATH
  );
  constructor(private http: HttpClient, private configuration: Configuration) {}

  getAllCountries() {
    const url = `${this.basePath}/country/allCountries`;
    return this.http.get<ImmigrationCountry[]>(url);
  }

  getDecisionsForCountryRules() {
    const url = `${this.basePath}/decisionupdate/getalldecisions`;
    return this.http.get<ImmigrationCountry[]>(url);
  }

  getUpdatedDate(countryValue: String) {
    const url = `${
      this.basePath
    }/decisionupdate/getupdateddate/${countryValue}`;

    return this.http.get(url);
  }

  setUpdatedDate(countries: Array < String > , updated: Date) {
    const url = `${this.basePath}/decisionupdate/update/date`;
    return this.http.put < ImmigrationCountryDateAndCycleSetResponse[] > (url, {
      countries: countries,
      date: updated
    });
  }

  setReminder(countries: Array < String > , reminder: number) {
    const url = `${this.basePath}/decisionupdate/update/reminder`;
    return this.http.put < ImmigrationCountryUpdateReminderSetResponse[] > (url, {
      countries: countries,
      state: reminder
    });
  }

  setUpdateCycle(countries: Array < String > , updateCycle: number) {
    const url = `${this.basePath}/decisionupdate/update/cycle`;
    return this.http.put < ImmigrationCountryDateAndCycleSetResponse[] > (url, {
      countries: countries,
      cycle: updateCycle
    });
  }

  getReminderReceivers(countryCode: String) {
    const url = `${this.basePath}/country-rules/${countryCode}/receivers`;
    return this.http.get<ImmigrationCountry>(url);
  }

  setStatus(countries: String[], target: String): Observable<DecisionUpdateDto[]> {
    const url = `${this.basePath}/decisionupdate/update/status`;
    return this.http.put<DecisionUpdateDto[]>(url, {
      countries: countries,
      status: target
    });
  }

  addReceiversToCountries(countries: String[], emails: String[]) {
    const url = `${this.basePath}/country-rules/receivers`;
    return this.http.post<any>(url, emails, {
      params: new HttpParams().append('countries', countries.join(','))
    });
  }

  setReceiversToCountries(countries: String[], emails: String[]) {
    const url = `${this.basePath}/country-rules/receivers`;
    return this.http.put<any>(url, emails, {
      params: new HttpParams().append('countries', countries.join(','))
    });
  }

  deleteReceiversFromCountries(countries: String[], emails: String[]) {
    const url = `${this.basePath}/country-rules/receivers/remove`;
    return this.http.post<any>(url, emails, {
      params: new HttpParams().append('countries', countries.join(','))
    });
  }

  exportReceiversToExcel(countries: String[]) {
    const url = `${this.basePath}/country-rules/receivers/export`;
    return this.http.get<any>(url, {
      responseType: 'blob' as 'json',
      params: new HttpParams().append('countries', countries.join(','))
    });
  }
}
