import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of as _of } from 'rxjs';
import { switchMap, tap, map, catchError, first } from 'rxjs/operators';

import { CompanyService } from '../services/company.service';
import { CustomershipService } from '../services/customership.service';
import { ProjectService } from '../services/project.service';
import {ImmiexpertCompanyService} from "../services/immiexpert-company.service";

@Injectable()
export class ProjectGuard implements CanActivate {
  constructor(
    private router: Router,
    private customershipService: CustomershipService,
    private companyService: CompanyService,
    private projectService: ProjectService,
    private immiexpertCompanyService: ImmiexpertCompanyService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.projectService
      .getSelectedProject()
      .pipe(
        first(),
        switchMap(project => {
          if (project) {
            return _of(true);
          } else {
            const id = next.params['projectId'];
            return this.projectService
              .getProject(id)
              .pipe(
                tap(p => this.projectService.selectProject(p)),
                switchMap(p => this.companyService.getCompany(p.companyId)),
                switchMap(c => this.immiexpertCompanyService.addCompanyToImmiIfNotExists(c)
                  .pipe(map(immiC => ({...c, ...immiC})))),
                tap(c => this.companyService.selectCompany(c)),
                switchMap(c =>
                  this.customershipService.getCustomership(
                    c['customershipId'],
                    true
                  )
                ),
                tap(c => this.customershipService.selectCustomership(c)),
                map(res => true),
                catchError(err => {
                  this.clearServices();
                  return _of(false);
                })
              );
          }
        })
      );
  }

  clearServices() {
    this.projectService.deselectProject();
    this.companyService.deselectCompany();
    this.customershipService.deselectCustomership();
    this.router.navigate(['']);
  }
}
