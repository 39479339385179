import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

export let isDebugMode = environment.isDebugMode && console !== undefined;

const noop = (): any => undefined;

@Injectable()
export class Logger {

  get info() {
    if (isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error() {
    if (isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }

  get log() {
    if (isDebugMode) {
      return console.log.bind(console);
    } else {
      return noop;
    }
  }

  get group() {
    if (isDebugMode) {
      return console.group.bind(console);
    } else {
      return noop;
    }
  }

  get groupEnd() {
    if (isDebugMode) {
      return console.groupEnd.bind(console);
    } else {
      return noop;
    }
  }

  constructor() { }
}
