import {Injectable} from '@angular/core';
import {SystemNotificationService} from './system-notification.service';
import {Configuration} from './configuration';
import {MatDialog} from '@angular/material/dialog';
import {
  ConfigurationKeys,
  SYSTEM_NOTIFICATION_IMMIEXPERT_KPMG_USER_ROLE,
  SYSTEM_NOTIFICATION_SERVICE_NAME_IMMIEXPERT,
  SYSTEM_NOTIFICATION_READ_NOTIFICATIONS_STORAGE_KEY,
  SYSTEM_NOTIFICATION_IMMIEXPERT_CLIENT_END_USER_ROLE
} from '../constants';
import {SystemNotificationComponent} from '../components/system-notification/system-notification.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {TimerObservable} from 'rxjs-compat/observable/TimerObservable';
import {SystemNotificationJSON} from '../models/systemNotificationJSON';
import {Logger} from './logger.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/first';
import 'rxjs/add/observable/of';
import {AuthService, SessionUserJSON, UserJSON, UserService} from 'access-common';
import {SessionService} from "./session.service";


@Injectable()
export class SystemNotificationPollerService {

  private hasNotificationsSubject = new BehaviorSubject<boolean>(undefined);
  private hasNotifications$: Observable<boolean> = this.hasNotificationsSubject.asObservable();
  private timer: Observable<any>;
  private data: SystemNotificationJSON[];
  private service: string;
  private role: string;
  private pollingInterval: number;
  private pollingInitialDelay: number;
  sessionUser: SessionUserJSON;

  constructor(private config: Configuration,
              private systemNotificationService: SystemNotificationService,
              private dialog: MatDialog,
              private logger: Logger,
              private userService: UserService,
              private authService: AuthService,
              private sessionService: SessionService
              ) {
    this.pollingInterval = this.config.getConfig(ConfigurationKeys.SYSTEM_NOTIFICATION_POLLING_INTERVAL) || 60000;
    this.pollingInitialDelay = this.config.getConfig(ConfigurationKeys.SYSTEM_NOTIFICATION_POLLING_INITIAL_DELAY) || 200;
  }

  /**
   * Get all available system notifications and show them in system notification modal
   * Remove the first read notification from the list
   * No for-loop is needed, since we want to show new notification only after the previous is closed
   * */
  private showAllNotifications(data: SystemNotificationJSON[]) {
    if (data && data.length > 0) {
      this.dialog.open(SystemNotificationComponent, {data: data[0]})
        .afterClosed()
        .subscribe(() => {
          const reduced: SystemNotificationJSON[] = [...data];
          reduced.splice(0, 1 );
          this.showAllNotifications(reduced);
        });
    }
  }

  /**
   * Helper method for other components to open system notification modal
   * */
  public showNotificationPopUp() {
    this.showAllNotifications(this.data);
  }

  /**
   * Poll notification service every X min for new notifications with correct role and service name
   * We can set different poller time interval in config.json
   * */
  pollNewSystemNotificationsForRoles(): Observable<any> {
    return this.timer = TimerObservable.create(this.pollingInitialDelay, this.pollingInterval)
      .map(() => {
        this.setQueryParamValues();
        if (this.role && this.service) {
          this.systemNotificationService.getNotificationsByRoleAndService(this.role, this.service)
            .first()
            .subscribe(data => {
              if (data) {
                this.data = data;
                this.checkForActiveNotificationsAndStoreAsRead();
              }
            }, error => {
              this.handleError(error);
            });
        }
      });
  }

  /**
   * Get all notification service roles for available notification service names according to roles
   * Currently for all KPMG users we only have one role, but this might change in the future
   * */
  setQueryParamValues() {
    this.service = SYSTEM_NOTIFICATION_SERVICE_NAME_IMMIEXPERT;
    this.sessionService.getSessionUser().subscribe(user => {
      this.sessionUser = user;
      const roleCheck = this.sessionUser.roles.map(role => role.name);
      if (roleCheck.toString() === 'KPMG System Admin' || roleCheck.toString() === 'KPMG Admin' || roleCheck.toString() === 'KPMG End User') {
        this.role = SYSTEM_NOTIFICATION_IMMIEXPERT_KPMG_USER_ROLE;
      } else if (roleCheck.toString() === 'Client End User') {
        this.role = SYSTEM_NOTIFICATION_IMMIEXPERT_CLIENT_END_USER_ROLE;
      } else {
       // we don't want to show any errors to user
        this.logger.warn(`No definition for required role and service parameters found`);
        this.role = null;
        this.service = null;
      }
    });
  }

  handleError(error) {
    this.logger.error(error);
  }

  /**
   * Boolean subscription method to hide or show the system notification triangle icon in components
   * */
  getNotificationIconObservable(): Observable<boolean> {
    return this.hasNotifications$;
  }

  /**
   * Check for active notifications and store the read ones into session storage, because it's browser specific
   * Open system notification modal for new notifications
   * */
  checkForActiveNotificationsAndStoreAsRead() {
    if (this.data.length > 0) {
      this.hasNotificationsSubject.next(true);
      const readNotificationString = sessionStorage.getItem(SYSTEM_NOTIFICATION_READ_NOTIFICATIONS_STORAGE_KEY);
      let readNotifications: string[];
      if (readNotificationString && readNotificationString.length > 0) {
        readNotifications = JSON.parse(readNotificationString);
      } else {
        readNotifications = [];
      }
      for (const notification of this.data) {
        if (!this.isNotificationRead(notification)) {
          readNotifications.push(notification.id);
          sessionStorage.setItem(SYSTEM_NOTIFICATION_READ_NOTIFICATIONS_STORAGE_KEY, JSON.stringify(readNotifications));
          this.dialog.open(SystemNotificationComponent, {data: notification})
            .afterClosed()
            .subscribe(() => {
              this.checkForActiveNotificationsAndStoreAsRead();
            }, error => {
              this.handleError(error);
            });
          return;
        }
      }
    } else {
      this.hasNotificationsSubject.next(false);
    }
    return Observable.of({});
  }

  /**
   * Check for already read notifications, so we don't show them twice if there are more than one incoming
   * */
  isNotificationRead(notification: SystemNotificationJSON): boolean  {
    const readNotifications = sessionStorage.getItem(SYSTEM_NOTIFICATION_READ_NOTIFICATIONS_STORAGE_KEY);
    if (readNotifications && readNotifications !== 'undefined' && readNotifications.length > 0) {
      return JSON.parse(readNotifications).some(n => n === notification.id);
    }
    return false;
  }
}
