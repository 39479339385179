import {Directive, HostBinding, HostListener} from '@angular/core';

@Directive({
  selector: '[appDropdownClick]'
})
export class DropdownClickDirective {

  @HostBinding('class.is-active')
  isOpen = false;

  @HostListener('click')
  toggle() {
    this.isOpen = !this.isOpen;
  }

  constructor() {
  }
}
