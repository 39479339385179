import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImmigrationUserRightsService } from '../../shared/services/immigrationUserRights.service';
import { ImmigrationUserRightsDto } from '../../shared/models/immigrationUserRights';
import { Configuration } from '../../shared/services/configuration';
import { ConfigurationKeys } from '../../shared/constants';
import { NotificationService } from '../../shared/services/notification.service';
import { NotificationModel } from '../../shared/models/notification.model';
import { ImmigrationCountry } from '../../shared/models/ImmigrationCountry';
import { CountryService } from '../../shared/services/country.service';
import { orderBy as _orderBy, startCase as _startCase, toLower as _toLower } from 'lodash';
import * as moment from 'moment';
import { animateIn, animateOut } from '../../shared/animations/animations';

@Component({
  selector: 'app-immigration-country-rules',
  templateUrl: './immigration-country-rules.component.html',
  styleUrls: ['./immigration-country-rules.component.css'],
  animations: [
    animateIn,
    animateOut
  ]
})
export class ImmigrationCountryRulesComponent implements OnInit {
  private id: String;
  public fileToUploadName: string;
  public immigrationUserRights: ImmigrationUserRightsDto;
  public uploadErrorTxt: string;
  public selectedFile: any;
  public uploadingFile: boolean;
  public uploadErrors: UploadError[];
  public rulePackages: RulePackageListItem[];
  public showUpdateDateModal: boolean;
  public showUpdateCycleModal: boolean;
  public windowWidth: number;
  countries: any;
  results = [];
  searchTerm = '';
  showDropdownMenu = false;
  showAddFilesModal = false;
  uploadMultipleFiles = undefined;
  selectedFiles = [];
  dateErrorMsg = '';
  countrySortReverse = true;
  countrySortingType = 'name';
  inputFocused = false;
  showUpdateReminderEmailsListModal = false;
  selectedCountry: any;
  hasReminderReceiverValidationError = false;
  allCountriesSelected = false;
  selectedCountriesCount = 0;
  showUpdateReceiversMultipleModal = false;
  eventType: string;
  emailReceivers: string;
  receiversListStr: string;
  momentNow = moment(new Date());
  verificationCode: string;
  verificationInput: string;
  hasVerificationCodeValidationError: boolean;
  emailsAdded: Array<string>;

  @HostListener('window:resize', ['$event'])
  getWindowWidth(event?) {
    this.windowWidth = window.innerWidth;
  }

  constructor(
    private configuration: Configuration,
    private http: HttpClient,
    private notifyService: NotificationService,
    private userRightsService: ImmigrationUserRightsService,
    private countryService: CountryService
  ) {
    this.getWindowWidth();
    this.verificationCode = this.randomStr(4);
  }

  private isValidEmail(email) {
    const re = /^(([^<>%{}()\\\/\[\]\\.,;:\s@"]+(\.[^<>{}()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private daysTo(date) {
    const diffDate = moment(new Date(date));
    const dur = moment.duration(diffDate.diff(moment(this.momentNow)));
    return dur.asDays();
  }

  private setCountryDataWithCountryCode(countryCode, properties) {
    const searchResults = this.results && this.results.length > 0;
    Object.keys(properties).forEach(key => {
      if (searchResults) {
        this.results.map(result => {
          if (result.value === countryCode) {
            result[key] = properties[key];
          }
        });
      } else {
        this.countries.map(result => {
          if (result.value === countryCode) {
            result[key] = properties[key];
          }
        });
      }
    });
  }

  private randomStr(length) {
    const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  private getSelectedCountries(countries) {
    const countryNames = [];
    const countryObjects = [];
    countries.forEach((c, idx) => {
      const countrySelected = (countries.length === 1) || (countries.length !== 1 && c.isSelected);
      if (countrySelected) {
        countryNames.push(c.value);
        countryObjects.push(c);
      }
    });
    return {
      names: countryNames,
      values: countryObjects
    };
  }

  abbrDateStr (dateStr) {
    return dateStr.replace('years', 'yr')
            .replace('months', 'mo')
            .replace('days', 'd')
            .replace('hours', 'h')
            .replace('minutes', 'min')
            .replace('seconds', 's');
  }

  getDecisions() {
    this.userRightsService.getUserRights().subscribe(rights => {
      this.immigrationUserRights = rights;
      if (!rights || !rights.statisticsView) {
        return;
      }
      this.countryService.getDecisionsForCountryRules().subscribe(countries => {
        countries.map(c => {
          c['daysToNextUpdate'] = this.daysTo(c.nextUpdate);
          c['nextUpdateStr'] = moment(new Date(c.nextUpdate)).fromNow().replace('in ', '');
          c['isSelected'] = this.countries && this.getSelectedCountries(this.countries).names.indexOf(c.value) > -1;
        });
        this.countries = countries;
      });
      this.loadRulePackageList();
    });
  }

  ngOnInit() {
    this.getDecisions();
    this.id = JSON.parse(localStorage.getItem('selectedProject'));
  }

  startCase (string) {
    return _startCase(_toLower(string));
  }

  editUpdatedDate(index: number) {
    const source = this.results.length === 0 ? this.countries : this.results;
    const date = new Date(source[index].updated);
    source[index].editedUpdatedDate = `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
    this.setCountryDataWithCountryCode(source[index].value, {
      'daysToNextUpdate': this.daysTo(this.countries[index].nextUpdate),
      'nextUpdateStr': moment(new Date(this.countries[index].nextUpdate)).fromNow().replace('in ', '')
    });
  }

  setStatus(countries: Array<ImmigrationCountry>, status) {
      let countryCodes: Array<string> = [];
      const isMulti = countries.length > 1;
      if (isMulti) {
        countries.forEach(c => {
          if (c['isSelected']) {
            countryCodes.push(c.value);
          }
        });
      } else {
        countryCodes = [countries[0].value];
      }
      this.countryService.setStatus(countryCodes, status).subscribe(res => {
        res.forEach((el, idx) => {
          this.setCountryDataWithCountryCode(el.name, {
            'currentStatus': el['currentStatus']
          });
        });
        this.notifyService.notify(
          new NotificationModel({
            level: 'success',
            isPopup: false,
            autoClose: true,
            msg: `Status set successfully`
          })
        );
      });
  }

  setUpdatedDate(countries: Array<ImmigrationCountry>, updatedValue) {
    if (this.checkDateFormat(updatedValue)) {
      const day = parseInt(updatedValue.substr(0, 2), 10);
      const month = parseInt(updatedValue.substr(3, 2), 10) - 1;
      const year = parseInt(updatedValue.substr(6, 4), 10);
      let countryCodes: Array<string> = [];
      const isMulti = countries.length > 1;
      let hasNotChanged = true;
      if (isMulti) {
        hasNotChanged = false;
        countries.forEach(c => {
          if (c['isSelected']) {
            countryCodes.push(c.value);
          }
        });
      } else {
        countryCodes = [countries[0].value];
        const d = countries[0].editedUpdatedDate.split('.')[1] + '.' + countries[0].editedUpdatedDate.split('.')[0] + '.' + countries[0].editedUpdatedDate.split('.')[2];
        hasNotChanged = !countries[0].editedUpdatedDate || (moment(countries[0].updated).format('DDMMYY') === moment(d).format('DDMMYY'));
      }
      if (hasNotChanged) {
        countries[0].editedUpdatedDate = undefined;
        return;
      }
      const updatedDate = new Date(year, month, day, 12, 0);
      this.countryService.setUpdatedDate(countryCodes, updatedDate).subscribe(res => {
        if (res) {
          this.showUpdateDateModal = false;
          this.notifyService.notify(
            new NotificationModel({
              level: 'success',
              isPopup: false,
              autoClose: true,
              msg: `Update date set successfully`
            })
          );
          res.forEach((el, idx) => {
            this.setCountryDataWithCountryCode(el.name, {
              'updated': updatedDate,
              'editedUpdatedDate': undefined,
              'nextUpdate': el.nextUpdate,
              'daysToNextUpdate': this.daysTo(el.nextUpdate),
              'nextUpdateStr': moment(el.nextUpdate).fromNow().replace('in ', '')
            });
          });
        }
      });
    }
  }

  checkDateFormat(value) {
    if (value && value.length !== 10) {
      this.dateErrorMsg = 'Date must be in dd.MM.yyyy format.';
      return false;
    }

    const day = value.substr(0, 2);
    const month = value.substr(3, 2);
    const year = value.substr(6, 4);
    const reg = new RegExp(/^\d+$/);

    if (!reg.test(day) || !reg.test(month) || !reg.test(year)) {
      this.dateErrorMsg = 'Date must be in dd.MM.yyyy format.';
      return false;
    }

    if (parseInt(day, 10) < 0 || parseInt(month, 10) < 0 || parseInt(year, 10) < 0) {
      this.dateErrorMsg = 'Date must be in dd.MM.yyyy format.';
      return false;
    }

    this.dateErrorMsg = '';
    return true;
  }

  toggleCountryUpdateReminder(countries, value) {
    const countryNames = this.getSelectedCountries(countries).names;
    if (countryNames.length < 1) {
      return;
    }
    this.countryService.setReminder(countryNames, value).subscribe(res => {
      if (res) {
        this.setUpdateReminderValues(res);
        this.notifyService.notify(
          new NotificationModel({
            level: 'success',
            isPopup: false,
            autoClose: true,
            msg: `Reminder ${value ? '' : 'un'}set successfully`
          })
        );
      }
    });
  }

  setUpdateReminderValues(countries) {
    const hasResults = this.results.length > 0,
          source = hasResults ? this.results : this.countries;
    source.forEach((el, eIdx) => {
      countries.forEach((cEl, cIdx) => {
        if (el.value === cEl.name) {
          if (hasResults) {
            this.results[eIdx].updateReminder = cEl.state;
          } else {
            this.countries[eIdx].updateReminder = cEl.state;
          }
        }
      });
    });
  }

  setUpdateCycle(countries: Array<any>, cycle: number) {
    const countryNames = this.getSelectedCountries(countries).names;
    if (countryNames.length < 1) {
      return;
    }
    this.countryService.setUpdateCycle(countryNames, cycle).subscribe(res => {
      if (res) {
        this.updateNextUpdateAndUpdateCycles(res, cycle);
        this.notifyService.notify(
          new NotificationModel({
            level: 'success',
            isPopup: false,
            autoClose: true,
            msg: `Update cycle set successfully`
          })
        );
        this.showUpdateCycleModal = false;
      }
    });
  }

  updateNextUpdateAndUpdateCycles(countries, updateCycle) {
    countries.forEach(c => {
      this.setCountryDataWithCountryCode(c.name, {
        'updateCycle': updateCycle,
        'nextUpdate': c.nextUpdate,
        'daysToNextUpdate': this.daysTo(c.nextUpdate),
        'nextUpdateStr': moment(c.nextUpdate).fromNow().replace('in ', '')
      });
    });
  }

  selectFile(event) {
    const src = event.target || event.srcElement;
    this.selectedFile = src.files[0];
    if (!this.uploadMultipleFiles) {
      this.selectedFiles.push(this.selectedFile);
    }
  }

  closeModal() {
    this.showAddFilesModal = false;
    this.selectedFile = undefined;
    this.selectedFiles = [];
  }

  dismissErrorMsg() {
    this.uploadErrorTxt = undefined;
  }

  showUploadFileModal(multiple: boolean) {
    this.uploadMultipleFiles = multiple;
    this.showAddFilesModal = true;
    this.showDropdownMenu = false;
  }

  uploadFile() {
    this.uploadErrors = [];
    if (!this.selectedFile || !this.selectedFile.name) {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: `No file selected for upload.`
        })
      );
      return;
    }
    const basePath = this.configuration.getConfig(ConfigurationKeys.IMMIGRATION_API_BASE_PATH);

    let url;
    if (this.uploadMultipleFiles) {
      url = `${basePath}/upload/zip`;
    } else {
      url = `${basePath}/upload/excel`;
    }

    const formData: FormData = new FormData();
    if (this.uploadMultipleFiles) {
      formData.append('file', this.selectedFile);
    } else {
      this.selectedFiles.forEach(f => {
        formData.append('file', f);
      });
    }

    this.uploadingFile = true;
    this.http.post(url, formData).subscribe(
      () => {
        this.uploadingFile = false;
        this.dismissErrorMsg();
        this.notifyService.notify(
          new NotificationModel({
            level: 'success',
            isPopup: false,
            autoClose: 7500,
            msg: `File ${this.selectedFile.name} uploaded successfully. Please review the changes on search page.`
          })
        );
        this.showAddFilesModal = false;
        this.selectedFile = undefined;
        this.selectedFiles = [];
        this.loadRulePackageList();
      },
      error => {
        this.uploadingFile = false;
        this.dismissErrorMsg();
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: `Error occurred while uploading decision trees file.
             Please fix errors listed above the file selector and try uploading again.`
          })
        );
        if (error && error.error) {
          const errorMsg = error.error.message;
          if (errorMsg && errorMsg.length > 0) {
            this.uploadErrorTxt = error.error.message;
          }
          if (error.error.length > 0) {
            this.uploadErrors = error.error.map(fileError => new UploadError(fileError.file, fileError.message));
          }
        }
      }
    );
  }

  downloadRulePackage(url: string, filename: string) {
    this.http.get(url, { responseType: 'blob' }).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/zip' });
        const blobURL = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = blobURL;
        document.body.appendChild(anchor);
        anchor.click();
        setTimeout(() => {
          document.body.removeChild(anchor);
        }, 250);
      },
      () => {
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: `Could not download the requested rule package. Please try downloading again.`
          })
        );
      }
    );
  }

  loadRulePackageList() {
    this.rulePackages = [];
    const basePath = this.configuration.getConfig(ConfigurationKeys.IMMIGRATION_API_BASE_PATH);
    this.http.get(`${basePath}/rulepackage`).subscribe(
      (packages: any) => {
        this.rulePackages = packages.map(rulePackage => this.convertToRulePackageListItem(rulePackage)).sort((a, b) => b.id - a.id);
        // .slice(0, 6);
      },

      () => {
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: `Could not load a list of the latest successfully uploaded rules.`
          })
        );
      }
    );
  }

  convertToRulePackageListItem(rulePackageResponse: any): RulePackageListItem {
    const basePath = this.configuration.getConfig(ConfigurationKeys.IMMIGRATION_API_BASE_PATH);
    return new RulePackageListItem(
      rulePackageResponse.id,
      rulePackageResponse.filename,
      rulePackageResponse.uploaderName,
      `${basePath}/rulepackage/${rulePackageResponse.id}/data`,
      new Date(rulePackageResponse.uploadedAt)
    );
  }

  filterData() {
    if (!this.searchTerm) {
      this.results = [];
      this.toggleCountrySorting(this.countrySortingType);
      return;
    }

    if (this.searchTerm.length === 1) {
      this.searchTerm = this.searchTerm.charAt(0).toUpperCase();
    }

    this.results = [];
    const secondaryResults = [];
    const inputLc = this.searchTerm.toLowerCase();

    this.countries.forEach(d => {
      const dLc = d.name.toLowerCase();
      if (dLc === inputLc) {
        /* If exactly same */
        this.results.push(d);
      } else if (inputLc === dLc.slice(0, inputLc.length)) {
        /* If contains same characters starting from the beginning */
        this.results.push(d);
      } else {
        const containsWhitespaces = dLc.indexOf(' ') > -1;
        if (containsWhitespaces) {
          for (let i = 0; i < dLc.length; i++) {
            /* If contains same characters after a whitespace */
            if (inputLc.length > 1 && dLc[i] === ' ' && dLc.slice(i + 1, i + 1 + inputLc.length) === inputLc) {
              secondaryResults.push(d);
            }
          }
        }
      }
    });

    if (secondaryResults.length > 0) {
      this.results = this.results.concat(secondaryResults);
    }
  }

  toggleCountrySorting(column) {
    this.countrySortReverse = column !== this.countrySortingType ? false : !this.countrySortReverse;

    this.countrySortingType = column;
    if (this.results.length === 0) {
      this.countries = _orderBy(this.countries, [column], [this.countrySortReverse ? 'asc' : 'desc']);
    } else {
      this.results = _orderBy(this.results, [column], [this.countrySortReverse ? 'asc' : 'desc']);
    }
  }

  deselectAllCountries() {
    const source = this.results.length > 0 ? this.results : this.countries;
    source.map(s => {
      s.isSelected = false;
      return s;
    });
    this.selectedCountriesCount = 0;
    this.allCountriesSelected = false;
  }

  showReminderReceiversModal(selectedCountry) {
    this.selectedCountry = selectedCountry;
    this.countryService.getReminderReceivers(selectedCountry.value).subscribe((receiversList: any) => {
      this.receiversListStr = receiversList.join(', ');
      this.emailReceivers = receiversList.join(', ');
    }, (error: any) => {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: `Error occurred while loading reminder receivers list. Please try loading receivers list again.`
        })
      );
    });
    this.showUpdateReminderEmailsListModal = true;
  }

  closeReminderReceiversModal() {
    this.selectedCountry = undefined;
    this.showUpdateReminderEmailsListModal = false;
    this.emailsAdded = [];
  }

  private showEmailUpdateMsg(type, msg) {
    if (!msg || msg.length < 1) {
      msg = (type === 'success') ? 'Email list saved successfully' : 'Email list saving failed. Please try saving again.';
    }
    this.notifyService.notify(
      new NotificationModel({
        level: type,
        isPopup: false,
        autoClose: true,
        msg: msg
      })
    );
  }

  saveReceiverEmails(type, country: ImmigrationCountry, emails) {
    emails = emails.replace(/\s/g, '');
    const emailsArr = emails.split(',');
    this.hasReminderReceiverValidationError = false;
    this.hasVerificationCodeValidationError = false;
    let hasValidationErrors = false;
    this.emailsAdded = [];
    const selected: string[] = country ? [country.value] : this.getSelectedCountries(this.countries).names;
    if (!selected || selected.length < 1) {
      return;
    }
    emailsArr.forEach(email => {
      if (!this.isValidEmail(email)) {
        hasValidationErrors = true;
      } else if (selected.length > 1) {
        this.emailsAdded.push(email);
      }
    });
    if (hasValidationErrors) {
      this.hasReminderReceiverValidationError = true;
      this.emailsAdded = [];
      return;
    }

    if (selected.length > 1 &&
        (!this.verificationInput ||  (this.verificationInput.toLowerCase() !== this.verificationCode.toLowerCase()))) {
      this.hasVerificationCodeValidationError = true;
      this.emailsAdded = [];
      return;
    }

    switch (type) {
      case 'overwrite':
      this.countryService.setReceiversToCountries(selected, emailsArr)
        .subscribe(() => {
          if (selected.length > 1) {
            this.verificationInput = undefined;
            this.emailReceivers = undefined;
          } else {
            this.showUpdateReminderEmailsListModal = false;
            this.getDecisions();
            this.showEmailUpdateMsg('success', undefined);
          }
        }, (error: any) => {
          this.showEmailUpdateMsg('error', undefined);
        });
      break;
      case 'add':
      this.countryService.addReceiversToCountries(selected, emailsArr)
        .subscribe(() => {
          this.verificationInput = undefined;
          this.emailReceivers = undefined;
          this.getDecisions();
          this.showEmailUpdateMsg('success', undefined);
        }, (error: any) => {
          this.showEmailUpdateMsg('error', undefined);
        });
      break;
      case 'remove':
      this.countryService.deleteReceiversFromCountries(selected, emailsArr)
        .subscribe(() => {
          this.verificationInput = undefined;
          this.emailReceivers = undefined;
          this.getDecisions();
          this.showEmailUpdateMsg('success', undefined);
        }, (error: any) => {
          this.showEmailUpdateMsg('error', undefined);
        });
      break;
    }
  }

  resetReceiverEmails(selectedCountry, emailReceivers) {
    this.emailReceivers = this.receiversListStr;
  }

  exportReceiversEmails(country: ImmigrationCountry) {
    const selected: string[] = country ? [country.value] : this.getSelectedCountries(this.countries).names;
    this.countryService.exportReceiversToExcel(selected)
    .subscribe(
      (res: any) => {
        const filePrefix = moment().format('YYMMDDHHmm');
        const filename = filePrefix + '_KPMG_Global_Immigration_Expert_Receiver_Emails';
        const extension = '.xlsx';
        if (navigator.appVersion.toString().indexOf('.NET') > -1) {
          window.navigator.msSaveBlob(res, filename + extension);
        } else {
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel'
          });
          const blobURL = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.download = filename + extension;
          anchor.href = blobURL;
          document.body.appendChild(anchor);
          anchor.click();
          setTimeout(() => {
            document.body.removeChild(anchor);
          }, 250);
        }
      },
      (error: Error) => {
        console.log(error);
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: 'Error occurred while exporting the receiver emails.'
          })
        );
      }
    );
  }

  toggleAllCountriesSelection(selected) {
    this.allCountriesSelected = selected;
    this.selectedCountriesCount = 0;
    this.countries.forEach(country => {
      country['isSelected'] = this.allCountriesSelected;
      if (country['isSelected']) {
        this.selectedCountriesCount += 1;
      }
    });
  }

  toggleCountrySelection(country) {
    if (country['isSelected']) {
      this.selectedCountriesCount += 1;
    } else {
      this.selectedCountriesCount -= 1;
    }
  }

  showReceiversMultipleModal(selectedCountries) {
    this.eventType = 'add';
    this.showUpdateReceiversMultipleModal = true;
  }

  closeReceiversMultipleModal() {
    this.getDecisions();
    this.showUpdateReceiversMultipleModal = false;
    this.eventType = undefined;
    this.emailsAdded = [];
  }
}

class UploadError {
  constructor(public file: string, public message: string) {}
}

class RulePackageListItem {
  constructor(public id: string, public filename: string, public uploaderName: string, public dataUrl: string, public uploadedAt: Date) {}
}
