import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { trigger, transition, style, animate } from '@angular/animations';

import { Configuration } from '../../shared/services/configuration';
import { ConfigurationKeys } from '../../shared/constants';

import { ImmigrationUserRightsService } from '../../shared/services/immigrationUserRights.service';
import { ImmigrationUserRightsDto } from '../../shared/models/immigrationUserRights';
import { Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { NotificationService } from '../../shared/services/notification.service';
import { NotificationModel } from '../../shared/models/notification.model';
import {CompanyJSON, CompanyService as CompanyAPI, UserService} from 'access-common';
import { CustomershipJSON } from 'access-common';
import { map, switchMap, first } from 'rxjs/operators';
import { filter as _filter } from 'lodash';
import { CompanyService } from '../../shared/services/company.service';
import { ImmigrationCompany } from '../../shared/models/ImmigrationCompany';
import { ImmiexpertCompanyService } from '../../shared/services/immiexpert-company.service';
import { animateIn, animateOut } from '../../shared/animations/animations';

@Component({
  selector: 'app-immigration-statistics',
  templateUrl: './immigration-statistics.component.html',
  styleUrls: ['./immigration-statistics.component.css'],
  animations: [
    animateIn,
    animateOut
  ]
})
export class ImmigrationStatisticsComponent implements OnInit {
  public immigrationUserRights: ImmigrationUserRightsDto;
  public customerships: CustomershipJSON[] = [];
  public companies: CompanyJSON[] = [];

  public basePath = this.configuration.getConfig(
    ConfigurationKeys.IMMIGRATION_API_BASE_PATH
  );

  public selectedCustomership: CustomershipJSON;
  public userInput = '';
  public companyInput = '';
  public selectedCompany: CompanyJSON;
  public filteredCustomerships: CustomershipJSON[] = [];
  public filteredCompanies: CompanyJSON[] = [];
  public hideList = true;
  public hideCompanyList = true;

  private id: String;

  public barChartOptions: any = {
    scaleShowVerticalLines: true,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      minHeight: 400
    },
    scales: {
      xAxes: [
        {
          ticks: {
            min: 0,
            beginAtZero: true,
            callback: function(value, index, values) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        }
      ]
    }
  };
  public countUpOptions = { useGrouping: true, separator: ' ' };
  public totalSearchesAmount: string;
  public barChartType = 'horizontalBar';
  public barChartLegend = true;
  public statisticsPeriod = {
    start: {
      value: '',
      isValid: false
    },
    end: {
      value: '',
      isValid: false
    }
  };

  public exportProcess = false;
  public statisticsLoading = false;
  private allStats = [];

  public collapseCitizenshipsList = false;
  public collapseDestinationsList = false;

  // barChartColors = ['#6FA4BD', '#ABDDFF', '#A9C9D7', '#7FC3D3'];

  public selectedChart: string;

  public barChartColors: Array<object> = [
    {
      backgroundColor: '#6FA4BD',
      borderColor: '#6FA4BD',
      pointBackgroundColor: '#6FA4BD',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#6FA4BD'
    },
    {
      backgroundColor: '#ABDDFF',
      borderColor: '#ABDDFF',
      pointBackgroundColor: '#ABDDFF',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#ABDDFF'
    }
  ];

  public chartLabels = {
    citizenships: [],
    destinations: []
  };

  public barChartLabels: string[] = this.chartLabels['citizenships'];

  public chartData = {
    citizenships: [
      // Current
      {
        data: [],
        label: null
      },
      // Reference period
      {
        data: [],
        label: null
      }
    ],
    destinations: [
      // Current
      {
        data: [],
        label: null
      },
      // Reference period
      {
        data: [],
        label: null
      }
    ]
  };

  public barChartData: any[] = this.chartData['citizenships'];
  public company: CompanyJSON & ImmigrationCompany;

  constructor(
    private cdr: ChangeDetectorRef,
    private userRightsService: ImmigrationUserRightsService,
    private http: HttpClient,
    private configuration: Configuration,
    private notifyService: NotificationService,
    private userApi: UserService,
    private companyApi: CompanyAPI,
    private companyService: CompanyService,
    private immiexpertCompanyService: ImmiexpertCompanyService
  ) {}

  ngOnInit() {
    this.userRightsService.getUserRights()
      .pipe(
        switchMap(userRights => this.immiexpertCompanyService.getSelectedCompany()
          .pipe(
            first(),
            map(company => ({
              userRights: userRights,
              company: company
            }))
          ))
      )
      .subscribe(combined => {
        this.immigrationUserRights = combined.userRights;
        this.company = combined.company;
        if (combined.userRights && combined.userRights.statisticsView) {
          this.getCustomerships();
        }
        this.id = JSON.parse(localStorage.getItem('selectedProject'));
      });
  }

  getCustomerships() {
    const url = `${this.basePath}/statistics/customerships`;
    this.statisticsLoading = true;
    this.http.get<any[]>(url).subscribe(res => {
      this.customerships = res;
      const selectedCustomershipIdLs = localStorage.getItem('selectedCustomership');
      if (selectedCustomershipIdLs && JSON.parse(selectedCustomershipIdLs) && JSON.parse(selectedCustomershipIdLs).length > 0) {
        const selectedCustomership = _filter(this.customerships, customership => {
          return customership.id === JSON.parse(selectedCustomershipIdLs);
        });
        if (selectedCustomership && selectedCustomership.length > 0) {
          this.selectCustomership(selectedCustomership[0]);
        }
      }
      this.setInitialPeriod();
      this.getStatisticsWithParameters();
    });
  }

  getCompaniesByCustomership(customership: CustomershipJSON): Observable<CompanyJSON[]> {
    return this.companyApi.findCompaniesUsingGET('**', customership.id)
      .pipe(
        map(companies => {
          return companies.filter(
            company => company.customershipId === customership.id
          );
        })
      );
  }

  setInitialPeriod() {
    const start = new Date();
    start.setMonth(start.getMonth() - 1);
    const end = new Date();
    this.statisticsPeriod['start'].value = this.formatDateForLabel(start);
    this.statisticsPeriod['start'].isValid = true;
    this.statisticsPeriod['end'].value = this.formatDateForLabel(end);
    this.statisticsPeriod['end'].isValid = true;
    this.deselectCompany();
  }

  clearStatisticsPeriod() {
    this.statisticsPeriod = {
      start: {
        value: '',
        isValid: false
      },
      end: {
        value: '',
        isValid: false
      }
    };
  }

  getStatisticsWithParameters() {
    if (new Date(this.getStartDate()) > new Date(this.getEndDate())) {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: 'Selected period is invalid. Check the dates and try again.'
        })
      );
      return;
    }

    const hasValidDates =
      this.statisticsPeriod['start'] &&
      this.statisticsPeriod['start'].value &&
      (this.statisticsPeriod['end'] && this.statisticsPeriod['end'].value);

    if (hasValidDates) {
      const start = this.getStartDate();
      const end = this.getEndDate();
      const referenecePeriod = this.getReferencePeriodDate();

      this.statisticsLoading = true;
      this.fetchStatisticsWithParams(
        referenecePeriod.refStart,
        referenecePeriod.refEnd,
        start,
        end
      ).subscribe(
        stats => {
          this.clearStatisticsData();
          this.sortStatisticsData(stats[1], 1, false);
          this.sortStatisticsData(stats[0], 0, true);
          this.setDateForLabels(referenecePeriod);
          this.selectedChart = undefined;
          this.selectChart('citizenships');
          this.totalSearchesAmount =
            stats[0] && stats[1]
              ? stats[0]['totalSearches']
                  // + stats[1]['totalSearches'] DO NOT DUPLICATE TOTAL SEARCHES AMOUNT
                  .toString()
              : undefined;
          this.statisticsLoading = false;
        },
        (error: Error) => {
          this.statisticsLoading = false;
        }
      );
    }
  }

  fetchStatisticsWithParams(
    referencePeriodStart,
    referencePeriodEnd,
    start,
    end
  ) {
    let url = `${this.basePath}/statistics/statistics?`;

    if (
      this.selectedCustomership &&
      this.selectedCustomership.customershipname
    ) {
      url = url + '&customership=' + this.selectedCustomership.id;
    } else {
      this.deselectCustomership();
    }

    if (this.selectedCompany && this.selectedCompany.companyname) {
      url = url + '&company=' + this.selectedCompany.id;
    } else {
      this.deselectCompany();
    }

    const referencePeriod = this.http.get<any[]>(
      url +
        `&from=${referencePeriodStart}T00:00:00Z&to=${referencePeriodEnd}T00:00:00Z`
    );

    const currentPeriod = this.http.get<any[]>(
      url + `&from=${start}T00:00:00Z&to=${end}T23:59:59Z`
    );

    return combineLatest([currentPeriod, referencePeriod]);
  }

  getStartDate() {
    return (
      this.statisticsPeriod['start'].value.slice(
        this.statisticsPeriod['start'].value.length - 4,
        this.statisticsPeriod['start'].value.length
      ) +
      '-' +
      this.statisticsPeriod['start'].value.slice(3, 5) +
      '-' +
      this.statisticsPeriod['start'].value.slice(0, 2)
    );
  }

  getEndDate() {
    return (
      this.statisticsPeriod['end'].value.slice(
        this.statisticsPeriod['end'].value.length - 4,
        this.statisticsPeriod['end'].value.length
      ) +
      '-' +
      this.statisticsPeriod['end'].value.slice(3, 5) +
      '-' +
      this.statisticsPeriod['end'].value.slice(0, 2)
    );
  }

  getReferencePeriodDate() {
    const end = new Date(this.getEndDate());
    const start = new Date(this.getStartDate());

    // start.setMonth(start.getMonth() + 1);
    // end.setMonth(end.getMonth() + 1);

    const durationInMs = Math.abs(end.getTime() - start.getTime());
    const refStart = new Date(start.getTime() - durationInMs);
    const refEnd = new Date(refStart.getTime() + durationInMs);

    const referencePeriod = {
      refStart: this.formatDateForBackend(refStart),
      refStartLabel: this.formatDateForLabel(refStart),
      refEnd: this.formatDateForBackend(refEnd),
      refEndLabel: this.formatDateForLabel(refEnd)
    };
    return referencePeriod;
  }

  formatDateForLabel(d: Date) {
    return `${('0' + d.getDate()).slice(-2)}.${('0' + (d.getMonth() + 1)).slice(
      -2
    )}.${d.getFullYear()}`;
  }

  formatDateForBackend(d: Date) {
    return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${(
      '0' + d.getDate()
    ).slice(-2)}`;
  }

  setDateForLabels(referenecePeriod) {
    this.chartData.citizenships[0].label = `${
      this.statisticsPeriod['start'].value
    } - ${this.statisticsPeriod['end'].value}`;
    this.chartData.destinations[0].label = `${
      this.statisticsPeriod['start'].value
    } - ${this.statisticsPeriod['end'].value}`;

    this.chartData.citizenships[1].label = `${
      referenecePeriod.refStartLabel
    } - ${referenecePeriod.refEndLabel}`;
    this.chartData.destinations[1].label = `${
      referenecePeriod.refStartLabel
    } - ${referenecePeriod.refEndLabel}`;
  }

  clearStatisticsData() {
    this.totalSearchesAmount = '0';
    this.chartLabels.citizenships = [];
    this.chartLabels.destinations = [];
    this.chartData.citizenships[0].data = [];
    this.chartData.citizenships[1].data = [];
    this.chartData.destinations[0].data = [];
    this.chartData.destinations[1].data = [];
    this.chartData.citizenships[0].label = null;
    this.chartData.citizenships[1].label = null;
    this.chartData.destinations[0].label = null;
    this.chartData.destinations[1].label = null;
  }

  getCountryLocaleWithName(countryName) {
    if (!countryName || !this.allStats) {
      return;
    }
    const filtered = this.allStats.filter(
      s => s.name.toLowerCase() === countryName.toLowerCase()
    );
    return filtered && filtered.length > 0 && filtered[0].locale
      ? filtered[0].locale.toLowerCase()
      : undefined;
  }

  sortStatisticsData(res, i: number, current: boolean) {
    this.allStats = res['citizenshipResults'].concat(res['hostCountryResults']);
    for (const c of res['citizenshipResults']) {
      if (current && this.chartLabels.citizenships.indexOf(c.name) < 0) {
        this.chartLabels.citizenships.push(c.name);
      }
      this.chartData.citizenships[i].data.push(c.count);
    }

    for (const c of res['hostCountryResults']) {
      if (current && this.chartLabels.destinations.indexOf(c.name) < 0) {
        this.chartLabels.destinations.push(c.name);
      }
      this.chartData.destinations[i].data.push(c.count);
    }
    this.collapseCitizenshipsList =
      this.chartLabels.citizenships &&
      this.chartLabels.citizenships.length > 10;
    this.collapseDestinationsList =
      this.chartLabels.destinations &&
      this.chartLabels.destinations.length > 10;
    // this.totalSearchesAmount += res.totalSearches;
  }

  filterCustomership() {
    if (this.userInput.length > 0) {
      this.hideList = false;
      this.filteredCustomerships = this.customerships.filter(c => {
        return (
          c.customershipname
            .toLowerCase()
            .indexOf(this.userInput.toLowerCase()) !== -1
        );
      });
    } else {
      this.selectedCustomership = null;
      this.hideList = true;
    }
  }

  selectCustomership(c: CustomershipJSON) {
    this.selectedCustomership = c;
    this.userInput = c.customershipname;
    this.hideList = true;
    this.getCompaniesByCustomership(c).subscribe(companies => {
      if (companies) {
        this.companies = companies;
      }
    });
  }

  deselectCustomership() {
    this.selectedCustomership = undefined;
    this.deselectCompany();
    this.userInput = undefined;
    this.hideList = true;
  }

  filterCompany() {
    if (this.companyInput && this.companyInput.length > 0 && this.companies) {
      this.hideCompanyList = false;
      this.filteredCompanies = this.companies.filter(c => {
        return (
          c.companyname
            .toLowerCase()
            .indexOf(this.companyInput.toLowerCase()) !== -1
        );
      });
    } else {
      this.selectedCompany = null;
      this.hideCompanyList = true;
    }
  }

  selectCompany(c: CompanyJSON) {
    this.selectedCompany = c;
    this.companyInput = c.companyname;
    this.hideCompanyList = true;
  }

  deselectCompany() {
    this.selectedCompany = null;
    this.companyInput = null;
    this.hideCompanyList = true;
  }

  chartClicked(e: any): void {
    // console.log(e);
  }

  chartHovered(e: any): void {
    // console.log(e);
  }

  selectChart(chart) {
    if (
      !chart ||
      chart.length < 1 ||
      !this.chartLabels ||
      chart === this.selectedChart
    ) {
      return;
    }
    this.selectedChart = undefined;
    setTimeout(() => {
      this.barChartLabels = this.chartLabels[chart].slice(0, 10);
      this.barChartData = this.chartData[chart].slice(0, 10);
      this.selectedChart = chart;
    }, 0);
    this.cdr.markForCheck();
  }

  checkStatisticsPeriodDate(type, event) {
    const src = event.target || event.srcElement;
    if (src.value && src.value.length >= 8) {
      const regex = new RegExp(
        '^(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}'
      );
      this.statisticsPeriod[type].isValid = regex.test(src.value);
    } else {
      this.statisticsPeriod[type].isValid = false;
    }
  }

  resetFilters() {
    this.deselectCustomership();
    this.clearStatisticsPeriod();
  }

  getSecondsStr(seconds) {
    return (seconds < 10 ? '0' : '') + seconds;
  }

  getPdfName() {
    const date = new Date();
    const timeStr = `${date.getFullYear()}${date.getMonth() + 1}
    ${date.getDate()}${date.getHours()}${date.getMinutes()}${this.getSecondsStr(
      date.getSeconds()
    )}`;
    const pdfName =
      timeStr + ' KPMG Global Immigration Expert Search Statistics';
    return pdfName;
  }

  exportPdf(type: string) {
    let url;
    if (type === 'pdf') {
      url = `${this.basePath}/statistics/statisticstopdf`;
    } else {
      url = `${this.basePath}/statistics/statisticstoexcel`;
    }
    this.exportProcess = true;

    const statisticData = [];
    const citizenshipStatistics = [];
    const destinationStatistics = [];

    for (let i = 0; i < this.chartLabels['citizenships'].length; i++) {
      citizenshipStatistics.push({
        countryName: this.chartLabels['citizenships'][i],
        data: this.chartData['citizenships'][0].data[i]
      });
    }

    if (this.collapseCitizenshipsList) {
      statisticData.push({
        category: 'Top 10 citizenships',
        statistics: citizenshipStatistics.slice(0, 10)
      });
    } else {
      statisticData.push({
        category: 'Citizenships',
        statistics: citizenshipStatistics
      });
    }

    for (let i = 0; i < this.chartLabels['destinations'].length; i++) {
      destinationStatistics.push({
        countryName: this.chartLabels['destinations'][i],
        data: this.chartData['destinations'][0].data[i]
      });
    }

    if (this.collapseDestinationsList) {
      statisticData.push({
        category: 'Top 10 destinations',
        statistics: destinationStatistics.slice(0, 10)
      });
    } else {
      statisticData.push({
        category: 'Destinations',
        statistics: destinationStatistics
      });
    }

    const usage =
      this.statisticsPeriod['start'].value +
      ' to ' +
      this.statisticsPeriod['end'].value;

    const data = {
      companyName: this.selectedCustomership
        ? this.selectedCompany
          ? this.selectedCustomership.customershipname +
            ': ' +
            this.selectedCompany.companyname
          : this.selectedCustomership.customershipname
        : 'All customerships',
      totalSearchAmount: parseFloat(this.totalSearchesAmount),
      timePeriod: usage,
      stats: statisticData
    };

    this.http
      .post(url, data, { responseType: 'blob' })
      .subscribe((res: any) => {
        if (navigator.appVersion.toString().indexOf('.NET') > -1) {
          window.navigator.msSaveBlob(res, this.getPdfName());
        } else {
          const blob = new Blob([res], { type: res['type'] });
          const blobURL = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          if (type === 'excel') {
            anchor.download = `${this.getPdfName()}.xlsx`;
          } else {
            anchor.download = this.getPdfName();
          }
          anchor.href = blobURL;
          document.body.appendChild(anchor);
          anchor.click();
          setTimeout(() => {
            document.body.removeChild(anchor);
          }, 250);
        }
        this.exportProcess = false;
      }, (error: Error) => {
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: 'Error in exporting statistics data. Please try exporting again.'
          })
        );
        this.exportProcess = false;
      });
  }
}
