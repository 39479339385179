import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { filter as _filter } from 'lodash';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Configuration } from '../../shared/services/configuration';
import { ConfigurationKeys } from '../../shared/constants';
import { ImmigrationQuestionService } from '../../shared/services/ImmigrationQuestion.service';
import { NotificationService } from '../../shared/services/notification.service';
import { NotificationModel } from '../../shared/models/notification.model';
import { CountryService } from '../../shared/services/country.service';
import { SectionJSON } from '../../shared/models/section';
import { ResultSectionJSON } from '../../shared/models/result-section';
import { SummaryResult } from '../../shared/models/summary-result';
import { b64EncodeUnicode } from '../../shared/base64';
import { animateIn, animateOut } from '../../shared/animations/animations';
import { navigateHome } from '../../shared/utils/navigate-home';
import { ComparisonResultJSON } from '../../shared/models/ImmigrationComparison';

@Component({
  selector: 'app-immigration-results',
  templateUrl: './immigration-results.component.html',
  styleUrls: ['./immigration-results.component.css'],
  animations: [
    animateIn,
    animateOut
  ]
})
export class ImmigrationResultsComponent implements OnInit {
  public questions: SummaryResult[] = [];
  public answer = {};

  private selectedProjectId: string;
  private selectedCompanyId: string;

  private searchId: string;

  public queryParams = {};

  public pdfExportProcess = false;

  public resultsDataLoaded = true;

  countryDataUpdated = undefined;

  sections: ResultSectionJSON[] = [];

  public hasHiddenSections = false;

  constructor(
    private countryService: CountryService,
    private immigrationQuestionService: ImmigrationQuestionService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private configuration: Configuration,
    private notifyService: NotificationService
  ) {
    this.route.queryParams.subscribe(params => (this.queryParams = params));
  }


  ngOnInit() {
    if (Object.keys(this.queryParams).length > 0) {
      Object.keys(this.queryParams).forEach((key: string) => {
        this.answer[key] = this.queryParams[key];
      });
      this.resultsDataLoaded = false;
      this.immigrationQuestionService.setAnswer(this.answer);
      this.getAnswer();
      this.getQuestions();
    } else {
      this.getAnswer();
    }
    this.selectedProjectId = JSON.parse(localStorage.getItem('selectedProject'));
    this.selectedCompanyId = JSON.parse(localStorage.getItem('selectedCompany'));
  }

  getAnswer() {
    this.immigrationQuestionService.fetchResult().subscribe(
      (comparisonResult: ComparisonResultJSON) => {
        this.resultsDataLoaded = true;
        this.searchId = comparisonResult.id;
        this.sections = comparisonResult.answer;
        this.countryService.getUpdatedDate(this.answer['DESTINATION']).subscribe(res => (this.countryDataUpdated = res['countryDataUpdated']));
      }, () => {
        this.resultsDataLoaded = true;
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: 'Error occurred while loading answer. Please refresh the page and try loading again.'
          })
        );
      }
    );
  }

  getQuestions() {
    this.immigrationQuestionService.getSummaryObj(this.answer).subscribe(res => {
      this.questions = res;
    });
  }

  newSearch() {
    navigateHome({}, null, this.router);
  }

  modifySearch() {
    const state = b64EncodeUnicode(JSON.stringify(this.queryParams));
    navigateHome(this.queryParams, state, this.router);
  }

  toggleVisibility(section: SectionJSON) {
    section.hidden = !section.hidden;
    const hiddenSections = _filter(this.sections, function(s) {
      let hiddenElCount = 0;
      s.elements.forEach(element => {
        if (element.hidden) {
          hiddenElCount = hiddenElCount + 1;
        }
      });
      return hiddenElCount > 0;
    });
    this.hasHiddenSections = hiddenSections.length > 0;
  }

  getSecondsStr(seconds) {
    return (seconds < 10 ? '0' : '') + seconds;
  }

  getPdfName() {
    const date = new Date();
    const timeStr = `${date.getFullYear()}${date.getMonth() + 1} ${date.getDate()}${date.getHours()}${date.getMinutes()}${this.getSecondsStr(date.getSeconds())}`;
    const pdfName = timeStr + ' KPMG Global Immigration Expert Search Results';
    return `${pdfName}.pdf`;
  }

  savePDF (data) {
    const usrNavigator = navigator || window.navigator;
    if (usrNavigator && (usrNavigator.msSaveOrOpenBlob || usrNavigator.msSaveBlob)) {
      if (usrNavigator.msSaveOrOpenBlob) {
        usrNavigator.msSaveOrOpenBlob(data, this.getPdfName());
      } else {
        usrNavigator.msSaveBlob(data, this.getPdfName());
      }
    } else {
      const blob = new Blob([data], { type: 'application/pdf' });
      const blobURL = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = this.getPdfName();
      anchor.href = blobURL;
      document.body.appendChild(anchor);
      anchor.click();
      setTimeout(() => {
        document.body.removeChild(anchor);
      }, 250);
    }
    this.pdfExportProcess = false;
  }

  exportPdf() {
    this.pdfExportProcess = true;
    const basePath = this.configuration.getConfig(ConfigurationKeys.IMMIGRATION_API_BASE_PATH);
    const url = `${basePath}/search/resultsToPdf/${this.searchId}`;
    const params = new HttpParams()
      .set('updated', this.countryDataUpdated);
    const headers = new HttpHeaders();
    this.http.get(url, { responseType: 'blob', headers: headers, params: params }).subscribe((res: any) => {
      setTimeout(() => {
        this.savePDF(res);
      }, 250);
    }, () => {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: 'Error occurred while downloading PDF. Please refresh the page and try downloading again.'
        })
      );
      this.pdfExportProcess = false;
    });
  }
}
