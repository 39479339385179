import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Parser, HtmlRenderer } from 'commonmark';

@Component({
  selector: 'app-immigration-resultcard',
  templateUrl: './immigration-resultcard.component.html',
  styleUrls: ['./immigration-resultcard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImmigrationResultcardComponent implements OnInit {
  private _data = new BehaviorSubject<String>(null);
  private _label = new BehaviorSubject<String>(null);
  private _icon = new BehaviorSubject<String>(null);
  private _isHidden = new BehaviorSubject<Boolean>(null);
  public styledText: string;

  @Input()
  set data(value) {
    this._data.next(value);
  }

  get data() {
    return this._data.getValue();
  }

  @Input()
  set label(value) {
    this._label.next(value);
  }

  get label() {
    return this._label.getValue();
  }

  @Input()
  set icon(value) {
    this._icon.next(value);
  }

  get icon() {
    return this._icon.getValue();
  }

  @Input()
  set isHidden(value) {
    this._isHidden.next(value);
  }

  get isHidden() {
    return this._isHidden.getValue();
  }

  @Input()
  isCollapsable;

  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    const parser = new Parser();
    const renderer = new HtmlRenderer();
    this._data.subscribe(text => {
      this.styledText = renderer.render(parser.parse(text));
    });
  }

  toggleVisibility() {
    if (this.isCollapsable) {
      this._isHidden.next(!this._isHidden.getValue());
      const data = {
        name: this._label.getValue(),
        value: this._isHidden.getValue()
      };
      this.change.emit(data);
    }
  }
}
