import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-link',
  templateUrl: './breadcrumb-link.component.html',
  styleUrls: ['./breadcrumb-link.component.css']
})
export class BreadcrumbLinkComponent {

  @Input('callback') callback: () => any;
  @Input('label') label: string;

  constructor() { }

}
