import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  preserveWhitespaces: true
})
export class LoadingSpinnerComponent implements OnInit {
  @Input()
  msg: string;
  @Input()
  spinnerStyle: string;

  constructor() {}

  ngOnInit() {
    this.msg = this.msg.toString();
    this.spinnerStyle = this.spinnerStyle.toString();
  }
}
