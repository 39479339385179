import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-immigration-main-page',
  templateUrl: './immigration-main-page.component.html',
  styleUrls: ['./immigration-main-page.component.css']
})
export class ImmigrationMainPageComponent implements OnInit {
  private resultPageUrls = [
    '/search/results',
    '/search/comparison'
  ];
  public isResultsPage: boolean = this.resultPageUrls.some(url => this.router.url.includes(url));
  public hasSVGSupport = false;

  constructor(private router: Router) {
    this.hasSVGSupport = typeof SVGRect !== 'undefined';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isResultsPage = this.resultPageUrls.some(url => event.urlAfterRedirects.includes(url));
      }
    });
  }

  ngOnInit() {}
}
