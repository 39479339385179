import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';
import {BASE_PATH, Configuration as HttpConfiguration} from 'access-common';
import {Configuration} from './configuration';
import {tap} from 'rxjs/internal/operators';
import {ConfigurationKeys} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  protected basePath = 'http://localhost:8000';
  public defaultHeaders = new HttpHeaders();
  httpConfiguration = new HttpConfiguration();

  constructor(protected httpClient: HttpClient,
              @Optional()@Inject(BASE_PATH) basePath: string,
              @Optional() httpConfiguration: HttpConfiguration,
              private configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (httpConfiguration) {
      this.httpConfiguration = httpConfiguration;
      this.basePath = basePath || httpConfiguration.basePath || this.basePath;
    }
  }

  public deleteSessionUsingDELETE(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteSessionUsingDELETE(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteSessionUsingDELETE(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteSessionUsingDELETE(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.httpConfiguration.selectHeaderAccept(httpHeaderAccepts);
    // tslint:disable-next-line:triple-equals
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.delete<any>(`${this.basePath}/session`,
      {
        withCredentials: this.httpConfiguration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  // This calls Move API to delete the session, since that is where it is stored 
  public endSession(): Observable<any> {
    return this.deleteSessionUsingDELETE()
      .pipe(
        tap(() => sessionStorage.removeItem('session')),
        tap(() => this.endConnectSession())
      );
  }
  // End the session in connect and redirect out of Immiexpert to avoid any issues there
  endConnectSession() {
    try {
      const portalUrl = this.configuration.getConfig(ConfigurationKeys.PORTAL_URL);
      if (portalUrl) {
        window.location.href = portalUrl + '/portal/logout';
      }
    } catch {
      location.reload();
    }
  }
}
