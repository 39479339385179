// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  basePath: 'http://localhost:8000',
  immigrationBasePath: 'https://localhost:8088',
  websocketPath: 'ws://localhost:8000/chat/live/websocket',
  portalUrl: 'https://dev-connect.trusteq.net',
  systemNotificationsEnabled: 'false',
  systemNotificationServiceURL: 'http://localhost:4201',
  systemNotificationPollingInterval: 60000,
  systemNotificationPollingInitialDelay: 2000,
  isDebugMode: false,
  oidcConfig: {
    stsServer: 'https://dev-connect.trusteq.net',
    redirect_url: 'http://localhost:4200/auth',
    client_id: [
      'iNJpOPTJGOQvqSuV3BZo4EgKbQYa'
    ],
    response_type: 'id_token token',
    scope: 'openid',
    post_logout_redirect_uri: 'localhost:4200/unauthorized',
    post_login_route: '/auth',
    forbidden_route: '/forbidden',
    unauthorized_route: '/unauthorized',
    log_console_warning_active: 'false',
    log_console_debug_active: 'false',
    max_id_token_iat_offset_allowed_in_seconds: 60,
    override_well_known_configuration: 'true',
    override_well_known_configuration_url: 'localhost:4200/assets/well-known-conf.json',
    trigger_authorization_result_event: 'true',
    silent_renew: 'false',
    storage: 'localStorage',
    well_known_endpoints: {
      issuer: 'https://dev-connect.trusteq.net:443/oauth2/token',
      authorization_endpoint: 'https://dev-connect.trusteq.net/oauth2/authorize',
      userinfo_endpoint: 'https://dev-connect.trusteq.net/oauth2/userinfo',
      token_endpoint: 'https://dev-connect.trusteq.net/oauth2/token',
      jwks_uri: 'https://dev-connect.trusteq.net/oauth2/jwks'
    }
  }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
