import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ImmigrationQuestionService } from '../../shared/services/ImmigrationQuestion.service';
import { SummaryResult } from '../../shared/models/summary-result';
import { animateIn, animateOut } from '../../shared/animations/animations';

@Component({
  selector: 'app-immigration-summary',
  templateUrl: './immigration-summary.component.html',
  styleUrls: ['./immigration-summary.component.css'],
  animations: [
    animateIn,
    animateOut
  ]
})
export class ImmigrationSummaryComponent implements OnInit {
  public questions: SummaryResult[] = [];

  public queryParams = {};
  public answer = {};
  public summaryDataLoaded = true;

  constructor(
    private immigrationQuestionService: ImmigrationQuestionService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(
      queryParams => (this.queryParams = queryParams)
    );
  }

  ngOnInit() {
    this.immigrationQuestionService.summaryVisited.next(true);

    const payload = [];

    Object.keys(this.queryParams).forEach((key: string) => {
      this.answer[key] = this.queryParams[key];
      payload.push({ key, value: this.queryParams[key] });
    });

    this.immigrationQuestionService
      .getSummary(this.queryParams['CITIZENSHIP'], this.queryParams['DESTINATION'], payload)
      .subscribe((response: SummaryResult[]) => {
        this.questions = response;
      });
  }

  onSubmit() {
    this.immigrationQuestionService.setAnswer(this.answer);
    this.router.navigate(['../results'], {
      relativeTo: this.route,
      queryParams: this.queryParams
    });
  }

  goBack() {
    this.router.navigate(['../extra-questions'], {
      relativeTo: this.route,
      queryParams: this.queryParams
    });
  }
}
