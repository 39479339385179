import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {findCookieValue} from '../utils/find-cookie-value';

export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
export const XSRF_COOKIE_NAME = 'XSRF-TOKEN';
export const XSRF_INTERCEPT_METHODS = ['PUT', 'POST', 'DELETE', 'PATCH'];

@Injectable()
export class XSRFInterceptor implements HttpInterceptor {


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: make URL configurable, transfer withCredential stuff to separate Interceptor
    let useCreds = (req.url.indexOf(".kpmg.fi") !== -1 || false);
    if (XSRF_INTERCEPT_METHODS.includes(req.method)) {
      const xsrfCookie = findCookieValue(XSRF_COOKIE_NAME);
      if (xsrfCookie) {
        return next.handle(req.clone({withCredentials: useCreds, headers: req.headers.set(XSRF_HEADER_NAME, xsrfCookie)}));
      }
    }

    return next.handle(req.clone({withCredentials: useCreds}));
  }

}
