import {Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {catchError} from 'rxjs/operators';

import { CompanyJSON } from 'access-common';
import {ConfigurationKeys} from '../constants';
import {HttpClient, HttpParams } from '@angular/common/http';
import {Configuration} from './configuration';
import {ImmigrationCompany} from '../models/ImmigrationCompany';

@Injectable()
export class ImmiexpertCompanyService {
  basePath = this.configuration.getConfig(
    ConfigurationKeys.IMMIGRATION_API_BASE_PATH
  ) + '/company';
  constructor(private http: HttpClient,
              private configuration: Configuration) {}

  getCompany(companyId: string): Observable<ImmigrationCompany> {
    const url = this.getUrlWithIdPathVariable(companyId);
    return this.http.get<ImmigrationCompany>(url);
  }

  getSelectedCompany(): Observable<ImmigrationCompany> {
    const companyId = JSON.parse(localStorage.getItem('selectedCompany'));
    return this.getCompany(companyId);
  }

  private getUrlWithIdPathVariable(id: string): string {
    return `${this.basePath}/${encodeURIComponent(id)}`;
  }

  addCompanyToImmiIfNotExists(company: CompanyJSON) {
    // Do nothing if getCompany succeeds, call createCompany if company not found
    return this.getCompany(company.id).pipe(catchError(err => {
      if (err.status === 404) {
        return this.createCompany({id: company.id, name: company.companyname});
      }
    }));

  }

  createCompany(company: ImmigrationCompany) {
    return this.http.post<ImmigrationCompany>(this.basePath, company);
  }

  getAllCompanies(): Observable<ImmigrationCompany[]> {
    return this.http.get<ImmigrationCompany[]>(this.basePath);
  }

  updateCompany(companyId: string, company: ImmigrationCompany, comment: string, requestAmount: string | null, validityPeriod: string | null): Observable<ImmigrationCompany> {
    const url = `${this.basePath}/${encodeURIComponent(companyId)}`;
    let param = new HttpParams();
    if (requestAmount) {
      param = param.append('requestAmount', requestAmount);
    }
    if (validityPeriod) {
      param = param.append('validityPeriod', validityPeriod);
    }
    if (comment) {
      param = param.append('comment', comment);
    }
    return this.http.put<ImmigrationCompany>(url, company,
      {
        params: param
      });
  }

  requestSearchQuotaIncrement(companyId: string, amount: number, comment: string) {
    const url = this.getUrlWithIdPathVariable(companyId)+ "/searchQuota";
    return this.http.post<boolean>(url, comment, {params: {'amount': <any>amount.toString()}});
  }
}
