import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { filter as _filter, find as _find } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { ImmigrationGeneralModel } from '../../shared/models/ImmigrationGeneralModel';
import { ImmigrationCountry } from '../../shared/models/ImmigrationCountry';
import { NotificationService } from '../../shared/services/notification.service';
import { NotificationModel } from '../../shared/models/notification.model';
import { CountryService } from '../../shared/services/country.service';
import { SearchService } from '../../shared/services/search.service';
import { FrontdoorService } from '../../shared/services/frontdoor.service';
import { ImmiexpertCompanyService } from '../../shared/services/immiexpert-company.service';
import { findExpirationDate } from '../../shared/utils/findExpirationDate';
import { parseDateToDDMMMMYYYY } from '../../shared/utils/parseDate';
import { isPackageValid } from '../../shared/utils/isPackageValid';
import { ImmigrationCompany } from 'src/app/shared/models/ImmigrationCompany';
import { ImmigrationLatestSearch } from '../../shared/models/ImmigrationLatestSearch';
import { animateIn, animateOut } from '../../shared/animations/animations';
import { getSearchedAtValue } from '../../shared/utils/get-search-time-string';

@Component({
  selector: 'app-immigration-search',
  templateUrl: './immigration-search.component.html',
  styleUrls: ['./immigration-search.component.scss'],
  preserveWhitespaces: true,
  animations: [
    animateIn,
    animateOut
  ]
})
export class ImmigrationSearchComponent implements OnInit {

  public allCountries: ImmigrationCountry[];
  public citizenship: ImmigrationGeneralModel;
  public destination: ImmigrationGeneralModel;
  public searchLoaded = false;
  public selectedDestination = false;
  public selectedCitizenship = false;
  public searchViewDataLoaded = false;
  public latestSearchesCount = 5;
  public latestSearches: Array<ImmigrationLatestSearch> = [];
  public lsCardPlaceholders: Array<any> = [];
  public frontdoorQuestions: Array<any> = [];
  public searchesSelectedToCompare: Array<ImmigrationLatestSearch> = [];
  public pathParams: Object = {};
  public availableSearches = -1;
  public expireDateFormatted: string;
  public isPackageValid: boolean;
  public selectedCompany: ImmigrationCompany;
  public showLatestSearches: boolean;
  public hideNewSearch: boolean;
  private selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  private currentUrlPath = location.pathname.replace('/apps/immigration-expert/', '');

  constructor(
    private countryService: CountryService,
    private router: Router,
    private route: ActivatedRoute,
    private notifyService: NotificationService,
    private searchService: SearchService,
    private frontdoorService: FrontdoorService,
    private immieCompService: ImmiexpertCompanyService
  ) {}

  ngOnInit() {
    this.immieCompService.getSelectedCompany()
      .pipe(
        first(),
        map(immigrationCompany => {
          this.selectedCompany = immigrationCompany;
          const expireDate = findExpirationDate(immigrationCompany.searchRequests);
          this.isPackageValid = isPackageValid(expireDate);
          this.expireDateFormatted = parseDateToDDMMMMYYYY(expireDate);
          return immigrationCompany.availableSearches;
        }),
        switchMap((availableSearches: number) => {
          if (availableSearches > 0 || availableSearches == null) {
            return forkJoin(
              this.openFrontdoor(),
              this.getLatestSearches()
            ).pipe(
              map(_ => availableSearches)
            );
          } else {
            return of(availableSearches);
          }
        })
      )
      .subscribe(availableSearches => {
        this.availableSearches = availableSearches;
      }, () => {
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: 'Error determining the search quota'
          })
        );
      });

    this.route.queryParams.subscribe((queryParams: any) => {
      Object.keys(queryParams).forEach(param => {
        this.pathParams[param] = queryParams[param];
      });
      this.setAnswersFromParams(this.frontdoorQuestions, this.pathParams);
    });
  }

  showComparison() {
    this.router.navigate(['comparison'], {
      relativeTo: this.route,
      queryParams: { id: this.searchesSelectedToCompare.map(search => search.id) }
    });
  }

  selectForCompare(search) {
    if (this.isCompareSelectionEnabled(search)) {
      this.searchesSelectedToCompare.push(search);
    }
  }

  removeFromCompare(search) {
    this.searchesSelectedToCompare = this.searchesSelectedToCompare.filter(item => item.id !== search.id);
  }

  setAnswersFromParams(questions, params) {
    if ((!questions || questions.length < 1) || !params) {
      return;
    }
    questions.forEach(q => {
      if (params[q.key] && params[q.key].length > 0) {
        const val = _find(q.possibleValues, (qpv) => {
          return qpv.value.toLowerCase() === params[q.key].toLowerCase();
        });
        if (!val || !val.value) {
          return;
        }
        q.selectedValue = val;
        if (q.key === 'CITIZENSHIP') {
          this.citizenship = { name: val.name, value: val.value };
          this.selectedCitizenship = true;
        } else if (q.key === 'DESTINATION') {
          this.destination = { name: val.name, value: val.value, status: val.status };
          this.selectedDestination = true;
        }
      }
    });
  }

  getLatestSearches() {
    return this.searchService.getLatestSearches(this.latestSearchesCount, this.selectedProject)
      .pipe(
        tap(latestSearches => {
          latestSearches.map(search => {
            search['searchedAtStr'] = getSearchedAtValue(search.searchedAt).fromNowStr;
          });
          this.latestSearches = latestSearches;
          if (latestSearches.length < this.latestSearchesCount) {
            const arrayLength = this.latestSearchesCount - latestSearches.length;
            this.lsCardPlaceholders = Array(arrayLength).fill('');
          }
        })
      );
  }

  isCompareSelectionEnabled(search): boolean {
    return this.searchesSelectedToCompare.length < 2 && !this.searchesSelectedToCompare.some(item => item.id === search.id);
  }

  openFrontdoor() {
    return this.frontdoorService.openFrontdoor(this.pathParams)
      .pipe(
        tap(res => {
          this.frontdoorQuestions = _filter(res, frontDoorQuestions => {
            // IM-138: Dirty fix to filter out the invalid "MAAT" country from the list
            frontDoorQuestions.possibleValues = _filter(frontDoorQuestions.possibleValues, question => {
              return question.name !== 'MAAT';
            });
            return frontDoorQuestions.key !== 'SECOND_CITIZENSHIP';
          });
          this.setAnswersFromParams(this.frontdoorQuestions, this.pathParams);
          this.searchViewDataLoaded = true;
        })
      );
  }

  private isEmptySpec(property) {
    return !property || !property.name || property.name.length < 2;
  }

  getCountryWithName(name) {
    if (!name) {
      return;
    }
    return _filter(
      this.allCountries,
      c => c.name && c.name.toLowerCase() === name.toLowerCase()
    )[0];
  }

  getSelectedByObject(payload: ImmigrationCountry[], alpha3: any) {
      if (!alpha3) {
        return null;
      }

      return payload.find((element) => element.value === alpha3.value);
  }

  getSelectedByAlpha3(payload: ImmigrationCountry[], alpha3: string) {
    if (!alpha3) {
      return null;
    }

    return payload.find((element) => element.value === alpha3);
}

  fetchQuestions() {
    if (this.citizenship.name === this.destination.name) {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: 'Selected destination is the same as traveller\'s citizenship. Please change either one and try again.'
        })
      );
    } else {
      if (this.destination && this.destination.name) {
        if (!this.isEmptySpec(this.getCountryWithName(this.destination.name))) {
          this.pathParams['DESTINATION'] = this.getCountryWithName(
            this.destination.name
          ).value;
        }
      }
      if (this.citizenship && this.citizenship.name) {
        if (!this.isEmptySpec(this.getCountryWithName(this.citizenship.name))) {
          this.pathParams['CITIZENSHIP'] = this.getCountryWithName(
            this.citizenship.name
          ).value;
        }
      }

      if (Object.keys(this.pathParams).length > 2) {
        Object.keys(this.pathParams).forEach((key: string) => {
          const allowedParams = ['CITIZENSHIP', 'DESTINATION', 'ACTIVITY', 'DURATION', 'RESIDENCE', 'state'];
          if (allowedParams.indexOf(key) < 0) {
            delete this.pathParams[key];
          }
        });
      }

      if (this.pathParams['CITIZENSHIP'] && this.pathParams['DESTINATION']) {
          this.router.navigate(['extra-questions'], {
            relativeTo: this.route,
            queryParams: this.pathParams
          });
      }
    }
  }

  getEncodedPathParams(params) {
    return '?' + Object.keys(params).map(function(key) {
      return key + '=' + encodeURI(params[key]);
    }).join('&');
  }

  getValueFromChild(event, inputKey, selected) {
    if (!event || !inputKey) {
      return;
    }
    if (!event.value) {
      delete this.pathParams[inputKey];
    } else {
      this.pathParams[inputKey] = event.value;
    }
    this.setUrlParams(this.pathParams);

    switch (inputKey) {
      case 'DESTINATION':
        this.destination = { name: event.name, value: event.value, status: selected && selected.status };
        this.selectedDestination =
          event.value && event.value.length > 0;
        break;
      case `CITIZENSHIP`:
        this.citizenship = { name: event.name, value: event.value };
        this.selectedCitizenship =
          event.value && event.value.length > 0;
        break;
    }
  }

  setUrlParams(params) {
    if (history && history.pushState) {
      history.pushState(null, null, this.currentUrlPath + this.getEncodedPathParams(params));
    }
  }

  updateURLparam(event) {
    const key: any = event.key.toUpperCase();
    if (!event.value) {
      delete this.pathParams[key];
      this.setUrlParams(this.pathParams);
    }
  }

  navigateToStatistics() {
    const projectId = JSON.parse(localStorage.getItem('selectedProject'));
    this.router.navigate([projectId, 'statistics']);
  }

  isSearchEnabled() {
    return ((this.availableSearches > 0  || this.availableSearches == null) && this.selectedCompany.status !== 0 && this.isPackageValid);
  }
}
