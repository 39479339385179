import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {SessionJSON, SessionService as SessionApi, SessionUserJSON} from 'access-common';
import {Observable} from 'rxjs/Observable';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {ProfileService} from "./profile.service";

@Injectable()
export class SessionService {
  private sessionSubject = new BehaviorSubject<SessionJSON>(undefined);
  private sessionUserSubject = new BehaviorSubject<SessionUserJSON>(undefined);

  private activeSession$ = this.sessionSubject.asObservable();
  private activeSessionUser$ = this.sessionUserSubject.asObservable();

  constructor(private sessionApi: SessionApi,
              private profileService: ProfileService) {
    this.profileService.getSessionUser()
      .subscribe(user => {
        // we do this spaghetti thing for now since we don't wanna fetch sessions. Preferably we would fetch session here
        // or remove the parts of the code that still check the session
        let session: SessionJSON = {
          userId: user.id
        }
        this.sessionUserSubject.next(user);
        this.sessionSubject.next(session);

      })
  }

  /**
   * The active session observable is "hot", so if we only want to take the current value at a time
   * and complete, use the .first() operator
   */
  public getActiveSession(): Observable<SessionJSON> {
    return this.activeSession$;
  }

  public getSessionUser(): Observable<SessionUserJSON> {
    return this.activeSessionUser$;
  }

  public updateSessionTos(newTosValue: boolean) {
    const session = _.cloneDeep(this.sessionSubject.getValue());
    session.tosApproved = newTosValue;
    this.sessionSubject.next(session);
  }

  public endSession(): Observable<any> {
    return this.sessionApi
      .deleteSessionUsingDELETE()
      .pipe(tap(() => this.sessionSubject.next(undefined)),
        tap(() => this.sessionUserSubject.next(undefined)))
  }
}
