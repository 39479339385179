import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { ImmigrationGeneralModel } from '../../../shared/models/ImmigrationGeneralModel';
import { camelCase as _camelCase } from 'lodash';

@Component({
  selector: 'app-immigration-select',
  templateUrl: './immigration-select.component.html',
  styleUrls: ['./immigration-select.component.css']
})
export class ImmigrationSelectComponent implements OnInit {
  private _data = new BehaviorSubject<String[]>([]);
  private _label = new BehaviorSubject<String>(null);
  private _selected = new BehaviorSubject<ImmigrationGeneralModel>(null);
  public selectedValue: ImmigrationGeneralModel = { name: null };

  @Input()
  set data(value) {
    this._data.next(value);
  }

  get data() {
    return this._data.getValue();
  }

  @Input()
  set label(value) {
    this._label.next(value);
  }

  get label() {
    return this._label.getValue();
  }

  @Input()
  set selected(value) {
    this._selected.next(value);
  }

  get selected() {
    return this._selected.getValue();
  }

  @Output()
  change: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this._selected.getValue()) {
      this.selectedValue = {
        name: this._selected.getValue().name
      };
    }
  }

  convertToCamelCase(label) {
    return _camelCase(label);
  }

  onSelect() {
    const userSelection = {
      name: this.label,
      value: this.selectedValue.name
    };
    this.change.emit(userSelection);
  }
}
