import { Component } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { NotificationModel } from '../../shared/models/notification.model';
import { animateIn, animateOut } from '../../shared/animations/animations';

@Component({
  selector: 'app-immigration-request-contact',
  templateUrl: './immigration-request-contact.component.html',
  styleUrls: ['./immigration-request-contact.component.scss'],
  animations: [
    animateIn,
    animateOut
  ]
})

export class ImmigrationRequestContactComponent {
  public isContactModalOpen = false;

  constructor(
    private notifyService: NotificationService
  ) {}

  copyToClipboard(text) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed'; selBox.style.left = '0'; selBox.style.top = '0'; selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus(); selBox.select();
    document.execCommand('copy');
    try {
      document.execCommand('copy');
      this.notifyService.notify(
        new NotificationModel({
          level: 'info',
          isPopup: false,
          autoClose: true,
          msg: 'Text copied to clipboard!'
        })
      );
    } catch (err) {
      this.notifyService.notify(
        new NotificationModel({
          level: 'error',
          isPopup: false,
          autoClose: true,
          msg: 'Oops! Unfortunately text copying is not supported in your browser.'
        })
      );
    } finally {
      document.body.removeChild(selBox);
    }
  }
}
