import {Configuration} from './configuration';
import {ConfigurationKeys} from '../constants';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, UserJSON as User, UserService as UserApi} from 'access-common';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {first} from 'rxjs/operators';

@Injectable()
export class ProfileService {
  private basePath: string;
  public configuration = APP_CONFIG;

  constructor(
    private config: Configuration,
    private userApi: UserApi,
    protected httpClient: HttpClient
  ) {
    this.basePath = this.config.getConfig(ConfigurationKeys.API_BASE_PATH);
  }

  /**
   * Get profile by id
   */
  getProfile(id: string): Observable<User> {
    return this.userApi.getUserUsingGET(id)
      .pipe(first());
  }

  /**
   * Used to get a limited user during authentication because to accept ToS we need a reference to the user first.
   *
   * @param {string} accessToken
   * @returns {Observable<any>}
   */
  getSessionUser() {
    return this.userApi.getSessionUserUsingGET();
  }
}
