import { Component, OnInit } from '@angular/core';
import { ImmiexpertCompanyService } from 'src/app/shared/services/immiexpert-company.service';
import { ImmigrationCompany } from 'src/app/shared/models/ImmigrationCompany';
import * as _ from 'lodash';

@Component({
  selector: 'app-immigration-admin-view',
  templateUrl: './immigration-admin-view.component.html',
  styleUrls: ['./immigration-admin-view.component.css']
})
export class ImmigrationAdminViewComponent implements OnInit {
  companyList: ImmigrationCompany[];
  searchString: string;


  constructor(
    private immiExpertCompanyService: ImmiexpertCompanyService) {
  }

  ngOnInit() {
    this.immiExpertCompanyService.getAllCompanies()
      .subscribe(companies => {
        this.companyList = _.sortBy(companies, 'name');
      });
  }
  }
