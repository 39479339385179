import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectService } from './shared/services/project.service';
import { first, switchMap, filter } from 'rxjs/operators';
import { of as _of } from 'rxjs';

@Injectable()
export class ImmigrationProjectGuard implements CanActivate {
  constructor(private router: Router, private projectService: ProjectService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.projectService
      .getSelectedProject()
      .pipe(
        filter(p => p !== undefined),
        first(),
        switchMap(project => {
          if (project && project.name) {
            const projectName = project.name.toLowerCase().replace(' ', '');
            if (projectName === 'immigrationexpert') {
              return _of(true);
            }
          }
          this.router.navigate(['/']);
          return _of(false);
        })
      );
  }
}
