import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Configuration } from './configuration';
import { ConfigurationKeys } from '../constants';
import { SearchParameterDto } from '../models/search-parameter-dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  basePath = this.configuration.getConfig(
    ConfigurationKeys.IMMIGRATION_API_BASE_PATH
  );
  constructor(private http: HttpClient, private configuration: Configuration) {}
  getLatestSearches(count: Number, selectedProject: string) {
    const params = {
      count: count,
      projectId: selectedProject
    };
    const serializedParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    const url = `${this.basePath}/search/latest?${serializedParams}`;
    return this.http.get<any>(url);
  }

  /**
   * Gets a list of search parameters of specified search
   * @param id Id of the search
   * @returns Observable of SearchParameterDto[] or if 403 permission denied in case
   * of search is not owned by this person, or 404 if search does not exist
   */
  getSearchParameters(id: Number): Observable<SearchParameterDto[]> {
    if (id === undefined || id === null) {
      throw new Error('Missing required parameter: "id"');
    }
    const url = `${this.basePath}/search/${id}`;
    return this.http.get<SearchParameterDto[]>(url);
  }
}
