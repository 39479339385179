import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import { ImmigrationMainPageComponent } from './components/immigration-main-page/immigration-main-page.component';
import { ImmigrationResultsComponent } from './components/immigration-results/immigration-results.component';
import { ImmigrationExtraQuestionsComponent } from './components/immigration-extra-questions/immigration-extra-questions.component';
import { ImmigrationSummaryComponent } from './components/immigration-summary/immigration-summary.component';
import { ImmigrationCountryRulesComponent } from './components/immigration-admin/immigration-country-rules.component';
import { ImmigrationStatisticsComponent } from './components/immigration-statistics/immigration-statistics.component';
import { ImmigrationTosComponent } from './tos/tos.component';
import { ImmigrationAuthGuard } from './immigration-tool.auth.guard';
import { ImmigrationProjectGuard } from './immigration-tool.project.guard';
import { ProjectGuard } from './shared/guards/project.guard';
import { ImmigrationSearchComponent } from './components/immigration-search/immigration-search.component';
import { ImmigrationUserRightsGuard } from './shared/guards/immigrationUserRights.guard';
import { ImmigrationTosGuard } from './tos/tos.guard';
import { ImmigrationAdminViewComponent } from './components/immigration-admin/immigration-admin-view/immigration-admin-view.component';
import { ImmigrationComparisonComponent } from './components/immigration-comparison/immigration-comparison.component';

export const immigrationRoutes: Routes = [
  {
    path: ':projectId',
    canActivate: [ProjectGuard, ImmigrationProjectGuard, ImmigrationUserRightsGuard],
    children: [
      {
        path: 'search',
        component: ImmigrationMainPageComponent,
        canActivate: [ImmigrationTosGuard],
        children: [
          {
            path: '',
            component: ImmigrationSearchComponent,
            data: { title: 'New search | KPMG Global Immigration Expert' }
          },
          {
            path: 'extra-questions',
            component: ImmigrationExtraQuestionsComponent,
            data: { title: 'Extra questions | KPMG Global Immigration Expert' }
          },
          {
            path: 'summary',
            component: ImmigrationSummaryComponent,
            data: { title: 'Search summary | KPMG Global Immigration Expert' }
          },
          {
            path: 'results',
            component: ImmigrationResultsComponent,
            data: { title: 'Search results | KPMG Global Immigration Expert' }
          },
          {
            path: 'comparison',
            component: ImmigrationComparisonComponent,
            data: { title: 'Searches comparison | KPMG Global Immigration Expert' }
          }
        ]
      },
      {
        path: 'country-rules',
        component: ImmigrationCountryRulesComponent,
        data: {
          title: 'Country rules dashboard | KPMG Global Immigration Expert'
        },
        canActivate: [ImmigrationTosGuard]
      },
      {
        path: 'admin-view',
        component: ImmigrationAdminViewComponent,
        canActivate: [ImmigrationTosGuard]
      },
      {
        path: 'statistics',
        component: ImmigrationStatisticsComponent,
        data: { title: 'Statistics | KPMG Global Immigration Expert' },
        canActivate: [ImmigrationTosGuard]
      },
      {
        path: 'tos',
        component: ImmigrationTosComponent,
        data: { title: 'Terms Of Service | KPMG Global Immigration Expert' }
      },
      { path: '**', redirectTo: 'search' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(immigrationRoutes, { enableTracing: false })],
  exports: [RouterModule]
})
export class ImmigrationToolRoutingModule {}
