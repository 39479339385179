import { Observable } from 'rxjs';
import { BreadcrumbsNode } from '../../../shared/models/breadcrumbs.node';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-dropdown',
  templateUrl: './breadcrumb-dropdown.component.html',
  styleUrls: ['./breadcrumb-dropdown.component.css']
})
export class BreadcrumbDropdownComponent implements OnInit {
  @Input('label')
  label: string;
  @Input('callback')
  callback: () => Observable<BreadcrumbsNode[]>;

  nodes: BreadcrumbsNode[];

  isOpen = false;

  constructor() {}

  ngOnInit() {}

  toggleOpen() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.callback().subscribe(nodes => {
        this.nodes = nodes;
      });
    }
  }
}
