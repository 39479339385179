import {AuthService, Session, SessionJSON} from 'access-common';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of as _of} from 'rxjs';
import {ProfileService} from './shared/services/profile.service';
import {NotificationService} from './shared/services/notification.service';
import {first, map, switchMap} from 'rxjs/operators';
import {SessionService} from "./shared/services/session.service";

@Injectable()
export class ImmigrationAuthGuard implements CanActivate {
  stateUrl: string;
  constructor(
    private router: Router,
    private session: Session,
    private authService: AuthService,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    private sessionService: SessionService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.sessionService.getActiveSession()
      .pipe(
        first(),
        switchMap(session => {
          if (!this.stateUrl) {
            this.stateUrl = JSON.stringify(state.url);
          }
          if (session) {
            if (!session.tosApproved) {
              this.authService.storeTargetRoute(this.stateUrl);
              this.router.navigateByUrl('/tos').then(success => {});
              return _of(false);
            }
            return this.profileService.getSessionUser().pipe(
              map(user => {
                return true;
              })
            );
          } else {
            // If not authenticated, authorize
            this.authService.storeTargetRoute(this.stateUrl);
            this.authService.authorize();
            return _of(false);
          }
        })
      );
  }
  checkSession(session: SessionJSON) {}
}
