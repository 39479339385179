import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { camelCase as _camelCase } from 'lodash';

@Component({
  selector: 'app-immigration-wa-select',
  templateUrl: './immigration-wa-select.component.html',
  styleUrls: ['./immigration-wa-select.component.css']
})
export class ImmigrationWaSelectComponent implements OnInit {
  private _data = new BehaviorSubject<Array<any>>([]);
  private _label = new BehaviorSubject<String>(null);
  private _selected = new BehaviorSubject<any>(null);
  public selectedValue = { name: null, value: null };
  public selectedWorkActivity: string;
  public workActivityList: any;
  public workActivityListCopy: any;
  public filteredWorkActivityList: boolean;
  public allWorkActivityTypes: any;
  public showWorkActivityList: boolean;

  @Input()
  set data(value) {
    this._data.next(value);
  }

  get data() {
    return this._data.getValue();
  }

  @Input()
  set label(value) {
    this._label.next(value);
  }

  get label() {
    return this._label.getValue();
  }

  @Input()
  set selected(value) {
    this._selected.next(value);
  }

  get selected() {
    return this._selected.getValue();
  }

  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this._selected.getValue()) {
      this.selectedValue = {
        name: this._selected.getValue().name,
        value: this._selected.getValue().value
      };
    }
    this.selectedWorkActivity = this.selectedValue.name;
    this.workActivityList = this.data;
    this.workActivityListCopy = this.data;
    this.allWorkActivityTypes = this.data;
  }

  convertToCamelCase(label) {
    return _camelCase(label);
  }

  onSelect() {
    const userSelection = {
      name: this.label,
      activity: this.selectedWorkActivity,
      value: this.selectedWorkActivity
    };
    this.change.emit(userSelection);
  }

  selectWorkActivity(option, parent) {
    this.selectedValue = option;
    this.selectedWorkActivity = parent
      ? parent.name + '; ' + option.name
      : option.name;
    this.onSelect();
    this.showWorkActivityList = false;
  }

  selectFilteredWorkActivity() {
    const filteredValue = this.workActivityList[0];
    if (filteredValue && filteredValue.name) {
      if (filteredValue.children && filteredValue.children.length > 0) {
        this.selectWorkActivity(filteredValue.children[0], undefined);
      } else {
        this.selectWorkActivity(filteredValue, undefined);
      }
    }
  }

  filterWorkActivityWithSearch(inputValue) {
    if (!inputValue) {
      return;
    }
    const foundWorkActivityTypes = [];
    this.allWorkActivityTypes.forEach(function(type) {
      const waTypeLine = type.name.toLowerCase();
      const waSearchValue = inputValue.toLowerCase().replace(' ', '');
      if (waTypeLine.indexOf(waSearchValue) >= 0) {
        foundWorkActivityTypes.push(type);
      }
    });
    return foundWorkActivityTypes;
  }

  filterWorkActivityList(inputValue) {
    if (
      !inputValue ||
      (inputValue && inputValue.replace(' ', '').length < 1) ||
      inputValue.type
    ) {
      this.filteredWorkActivityList = false;
      this.showWorkActivityList = true;
      this.workActivityList = this.workActivityListCopy;
    } else {
      this.filteredWorkActivityList = true;
      this.workActivityList = this.filterWorkActivityWithSearch(inputValue);
    }
  }
}
