import {Component, OnInit} from '@angular/core';
import {CompanyService, CustomershipJSON, CustomershipService, SessionJSON, UserJSON} from 'access-common';
import {ProfileService} from '../shared/services/profile.service';
import {ProjectService} from '../shared/services/project.service';
import {Observable} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {SessionService} from "../shared/services/session.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  mobile = false;

  session$: Observable<SessionJSON>;
  user$: Observable<UserJSON>;
  project$: Observable<CustomershipJSON>;

  constructor(
    private sessionService: SessionService,
    private profileService: ProfileService,
    private companyService: CompanyService,
    private customershipService: CustomershipService,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.session$ = this.sessionService.getActiveSession();
    this.user$ = this.profileService.getSessionUser();
    this.project$ = this.session$
      .pipe(filter(session => session !== undefined))
      .pipe(switchMap(session => this.projectService.getSelectedProject()));
  }
}
