import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ChartsModule} from 'ng2-charts';
import {CountUpModule} from 'countup.js-angular2';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {Configuration, SYSTEM_NOTIFICATION_URL} from './shared/services/configuration';
import {TranslateModule} from '@ngx-translate/core';
import {ImmigrationMainPageComponent} from './components/immigration-main-page/immigration-main-page.component';
import {ImmigrationSearchComponent} from './components/immigration-search/immigration-search.component';
import {ImmigrationResultsComponent} from './components/immigration-results/immigration-results.component';
import {ImmigrationResultcardComponent} from './components/immigration-results/immigration-resultcard/immigration-resultcard.component';
import {ImmigrationExtraQuestionsComponent} from './components/immigration-extra-questions/immigration-extra-questions.component';
import {ImmigrationSpecsService} from './shared/services/immigrationSpecs.service';
import {ImmigrationQuestionService} from './shared/services/ImmigrationQuestion.service';
import {ImmigrationSummaryComponent} from './components/immigration-summary/immigration-summary.component';
import {ImmigrationSelectComponent} from './components/immigration-extra-questions/immigration-select/immigration-select.component';
import {ImmigrationWaSelectComponent} from './components/immigration-extra-questions/immigration-wa-select/immigration-wa-select.component';
import {ImmigrationInputComponent} from './components/immigration-input/immigration-input.component';
import {ImmigrationProgressComponent} from './components/immigration-progress/immigration-progress.component';
import {ImmigrationCountryRulesComponent} from './components/immigration-admin/immigration-country-rules.component';
import {ImmigrationStatisticsComponent} from './components/immigration-statistics/immigration-statistics.component';
import {ImmigrationTosComponent} from './tos/tos.component';
import {ImmigrationTosGuard} from './tos/tos.guard';
import {ImmigrationProjectGuard} from './immigration-tool.project.guard';
import {ImmigrationAuthGuard} from './immigration-tool.auth.guard';
import {ImmigrationToolRoutingModule} from './immigration.routing.module';
import {ImmigrationUserRightsService} from './shared/services/immigrationUserRights.service';
import {ImmigrationUserRightsGuard} from './shared/guards/immigrationUserRights.guard';
import {ProjectService} from './shared/services/project.service';
import {ProfileService} from './shared/services/profile.service';
import {CompanyService} from './shared/services/company.service';
import {CustomershipService} from './shared/services/customership.service';
import {NotificationService} from './shared/services/notification.service';
import {NotificationComponent} from './shared/components/notification/notification.component';
import {SessionService} from './shared/services/session.service';
import {LoadingSpinnerComponent} from './shared/components/loading-spinner/loading-spinner.component';
import {TabsComponent} from './navigation/tabs/tabs.component';
import {NavigationComponent} from './navigation/navigation.component';
import {BreadcrumbsComponent} from './navigation/breadcrumbs/breadcrumbs.component';
import {BreadcrumbLinkComponent} from './navigation/breadcrumbs/breadcrumb-link/breadcrumb-link.component';
import {BreadcrumbDropdownComponent} from './navigation/breadcrumbs/breadcrumb-dropdown/breadcrumb-dropdown.component';
import {ImmigrationTabsComponent} from './components/immigration-tabs/immigration-tabs.component';
import {LaddaModule} from 'angular2-ladda';
import {ProjectGuard} from './shared/guards/project.guard';
import {TopNavigationComponent} from './navigation/top-navigation/top-navigation.component';
import {MatCheckboxModule, MatDialogModule, MatSnackBarModule} from '@angular/material';
import {MatRadioModule} from '@angular/material/radio';
import {NotificationPopupComponent} from './shared/components/notification/notification-popup/notification-popup.component';
import {Logger} from './shared/services/logger.service';
import {SystemNotificationPollerService} from './shared/services/system-notification-poller.service';
import {SystemNotificationService} from './shared/services/system-notification.service';
import {ConfigurationKeys} from './shared/constants';
import {SystemNotificationComponent} from './shared/components/system-notification/system-notification.component';
import {ImmiexpertCompanyService} from './shared/services/immiexpert-company.service';
import {ImmigrationAdminViewComponent} from './components/immigration-admin/immigration-admin-view/immigration-admin-view.component';
import {DropdownClickDirective} from './shared/directives/dropdown-click.directive';
import {FilterPipe} from './shared/pipes/filter-pipe';
import {AccessCommonModule, AuthService} from 'access-common';
import {SearchQuotaFormComponent} from './components/immigration-statistics/search-quota-form/search-quota-form.component';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {ImmigrationAdminViewRowComponent} from './components/immigration-admin/immigration-admin-view/immigration-admin-view-row/immigration-admin-view-row.component';
import {ImmigrationAdminViewSearchPackageRowComponent} from './components/immigration-admin/immigration-admin-view/immigration-admin-view-row/immigration-admin-view-search-package-row/immigration-admin-view-search-package-row';
import {XSRFInterceptor} from './shared/services/xsrf.interceptor';
import {ImmigrationComparisonComponent} from './components/immigration-comparison/immigration-comparison.component';
import {ImmigrationLatestSearchComponent} from './components/immigration-latest-search/immigration-latest-search.component';
import {
  ImmigrationRequestContactComponent
} from './components/immigration-request-contact/immigration-request-contact.component';

@NgModule({
  declarations: [
    AppComponent,
    ImmigrationMainPageComponent,
    ImmigrationResultsComponent,
    ImmigrationComparisonComponent,
    ImmigrationRequestContactComponent,
    ImmigrationLatestSearchComponent,
    ImmigrationResultcardComponent,
    ImmigrationSearchComponent,
    ImmigrationExtraQuestionsComponent,
    ImmigrationSummaryComponent,
    ImmigrationSelectComponent,
    ImmigrationWaSelectComponent,
    ImmigrationInputComponent,
    ImmigrationProgressComponent,
    ImmigrationCountryRulesComponent,
    ImmigrationStatisticsComponent,
    ImmigrationTosComponent,
    LoadingSpinnerComponent,
    TabsComponent,
    NavigationComponent,
    BreadcrumbsComponent,
    BreadcrumbLinkComponent,
    BreadcrumbDropdownComponent,
    ImmigrationTabsComponent,
    TopNavigationComponent,
    NotificationComponent,
    NotificationPopupComponent,
    SystemNotificationComponent,
    ImmigrationAdminViewComponent,
    DropdownClickDirective,
    FilterPipe,
    SearchQuotaFormComponent,
    ImmigrationAdminViewRowComponent,
    ImmigrationAdminViewSearchPackageRowComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LaddaModule,
    ImmigrationToolRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    CountUpModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSelectModule,
    MatInputModule,
    AccessCommonModule.forRoot(),
    TranslateModule.forRoot()
  ],
  providers: [
    ImmigrationUserRightsService,
    ImmigrationUserRightsGuard,
    ImmigrationSpecsService,
    ImmigrationQuestionService,
    ImmigrationAuthGuard,
    ImmigrationProjectGuard,
    ImmigrationTosGuard,
    ImmiexpertCompanyService,
    ProjectGuard,
    AuthService,
    ProjectService,
    ProfileService,
    CompanyService,
    CustomershipService,
    NotificationService,
    SessionService,
    Configuration,
    SystemNotificationService,
    SystemNotificationPollerService,
    Logger,
    {
      provide: SYSTEM_NOTIFICATION_URL,
      useFactory: systemNotifPathFactory,
      deps: [Configuration]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XSRFInterceptor,
      multi: true
    },
  ],
  entryComponents: [
    SystemNotificationComponent,
  ],
  bootstrap: [AppComponent],
  exports: [
    SystemNotificationComponent,
    DropdownClickDirective
  ]
})
export class AppModule {
}

export function systemNotifPathFactory(config: Configuration) {
  return config.getConfig(ConfigurationKeys.SYSTEM_NOTIFICATION_URL);
}

