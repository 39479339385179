/**
 * Finds cookieName from document cookies
 * @param cookieName
 */
export function findCookieValue(cookieName: string) {
  const cookies = getDocumentCookiesAsObject();
  return cookies[cookieName];
}

function getDocumentCookiesAsObject() {
  const cookies = document.cookie.split(';');
  const cookiesObj = {};
  cookies
    .map(cookie => cookie.trim())
    .forEach(cookie => {
    const separatorIndex = cookie.indexOf('=');
    const cookieKey = cookie.substr(0, separatorIndex);
    cookiesObj[cookieKey] = cookie.substr(separatorIndex + 1, cookie.length);
  });
  return cookiesObj;
}
