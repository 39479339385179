import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Configuration, SYSTEM_NOTIFICATION_URL} from './configuration';
import {SystemNotificationJSON} from '../models/systemNotificationJSON';
import {CustomHttpUrlEncodingCodec} from 'access-common/lib/api-client/encoder';


@Injectable()
export class SystemNotificationService {
  protected basePath: string;

  constructor(private httpClient: HttpClient,
              private configuration: Configuration,
              @Inject(SYSTEM_NOTIFICATION_URL) basePath: string) {

    if (basePath) {
      this.basePath = basePath;
    }
  }

  public getNotificationsByRoleAndService(roleName: string, serviceName: string) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json; charset=utf8');

    if (roleName === null || roleName === undefined) {
      throw new Error('Required parameter roleName was null or undefined when calling getNotificationsByRoleAndService.');
    }
    if (serviceName === null || serviceName === undefined) {
      throw new Error('Required parameter serviceName was null or undefined when calling getNotificationsByRoleAndService.');
    }
    let queryParameters = new HttpParams();
    if (roleName !== undefined) {
      queryParameters = queryParameters.set('roleName', <any>roleName);
    }
    if (serviceName !== undefined) {
      queryParameters = queryParameters.set('serviceName', <any>serviceName);
    }

    return this.httpClient.get<Array<SystemNotificationJSON>>(`${this.basePath}/notif`,
      { headers: headers,  params: queryParameters});
  }
}
