import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {SessionUserJSON, UserService} from 'access-common';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ImmigrationUserRightsService {
  private userRightsListChangedSource = new Subject<void>();
  userRightsListChanged$ = this.userRightsListChangedSource.asObservable();

  constructor(
    private accessUserApi: UserService
  ) {}

  notifyUserRightsListChange() {
    this.userRightsListChangedSource.next();
  }

  getUserRights(): Observable<any> {
    return this.accessUserApi.getSessionUserUsingGET()
    .pipe(
      map((res: SessionUserJSON) => {
        const rights = {
          searchView: true,
          statisticsView: true,
          adminView: false,
          countryRules: false
        };
        if (res.roles) {
          const adminRoles = [
            'KPMG System Admin',
            'KPMG Admin'
          ];
          let adminRolesCount = 0;
          adminRoles.forEach(role => {
            if (
              _.findLastIndex(res.roles, {
                name: role
              }) > -1
            ) {
              adminRolesCount = adminRolesCount + 1;
            }
          });
          if (adminRolesCount > 0) {
            rights.adminView = true;
            rights.statisticsView = true;
            rights.countryRules = true;
          }
          if (res.restrictedRoleName === 'KPMG End User') {
            rights.countryRules = true;
          }
        }
        // const hasEntityAddingRight = session && session.userId && session.accessToken && session.tosApproved;
        return rights;
      })
    );
  }
}
