import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotificationModel } from '../../../models/notification.model';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.css']
})
export class NotificationPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public notification: NotificationModel,
    private dialogRef: MatDialogRef<NotificationPopupComponent>
  ) {}

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }
}
