import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService, CompanyJSON } from 'access-common';
import { map } from 'rxjs/operators';
import { find as _find } from 'lodash';
import { ImmigrationCompany } from '../../../shared/models/ImmigrationCompany';
import { ImmiexpertCompanyService } from '../../../shared/services/immiexpert-company.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationModel } from 'src/app/shared/models/notification.model';
import { parseDateToDDMMYYYY } from 'src/app/shared/utils/parseDate';
import { findExpirationDate } from 'src/app/shared/utils/findExpirationDate'
import {SessionService} from "../../../shared/services/session.service";
@Component({
  selector: 'app-search-quota-form',
  templateUrl: 'search-quota-form.component.html',
  styleUrls: [
    '../immigration-statistics.component.css',
    'search-quota-form.component.css'
  ]
})
export class SearchQuotaFormComponent implements OnInit {
  @Input()
  totalSearchesAmount = 0;

  @Input()
  previousRequestQuantity = 0;

  @Input()
  company: CompanyJSON & ImmigrationCompany;

  requesterFullName$: Observable<string>;
  form: FormGroup;
  countUpOptions = {useGrouping: true, separator: ' '};
  expirationDate: string;

  constructor(private sessionService: SessionService,
              private notifyService: NotificationService,
              private companyService: ImmiexpertCompanyService) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      quantity: new FormControl(null, Validators.required),
      comment: new FormControl('', [Validators.required, Validators.minLength(3)])
    });
    this.requesterFullName$ = this.sessionService.getSessionUser()
      .pipe(
        map(user => user.firstName + ' ' + user.lastName)
      );
    this.expirationDate = parseDateToDDMMYYYY(findExpirationDate(this.company.searchRequests));
  }

  submit() {
    this.companyService.requestSearchQuotaIncrement(this.company.id, this.form.get('quantity').value, this.form.get('comment').value)
      .subscribe(() => {
        this.form.reset({
          quantity: null,
          comment: ''
        });
        this.notifyService.notify(
          new NotificationModel({
            level: 'success',
            isPopup: false,
            autoClose: true,
            msg: 'Request sent successfully'
          })
        );
      }, () => {
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: 'Request could not be sent'
          }));
      });
  }
}
