/* Helper class for constants */

/**
 * TODO: this should not be a constant, it should be configured somehow
 */
export const AVATAR_PATH = '/avatar/';
export const COMMENT_ATTACHMENT_PATH = '/comment/';
export const TASK_ATTACHMENT_PATH = '/task/';

export const DISCUSSION_PAGE_SIZE = 15;

/**
 * Configuration keys
 */

export namespace ConfigurationKeys {
    export const API_BASE_PATH = 'basePath';
    export const CALLBACK_URL = 'callbackPath';
    export const WEBSOCKET_PATH = 'websocketPath';
    export const PORTAL_URL = 'portalUrl';
    export const CLIENT_ID = 'clientId';
    export const OIDC_DEBUG = 'oidcDebug';
    export const VEROKONE_API_BASE_PATH = 'verokoneBasePath';
    export const IMMIGRATION_API_BASE_PATH = 'immigrationBasePath';
    export const SYSTEM_NOTIFICATIONS_ENABLED = 'systemNotificationsEnabled';
    export const SYSTEM_NOTIFICATION_URL = 'systemNotificationServiceURL';
    export const SYSTEM_NOTIFICATION_POLLING_INTERVAL = 'systemNotificationPollingInterval';
    export const SYSTEM_NOTIFICATION_POLLING_INITIAL_DELAY = 'systemNotificationPollingInitialDelay';
}

export const KPMG_SYSTEM_ADMIN_ROLE_NAME = 'KPMG System Admin';
export const KPMG_ADMIN_ROLE_NAME = 'KPMG Admin';
export const KPMG_END_USER_ROLE_NAME = 'KPMG End User';

/**
 * Notification Service roles for ImmiExpert
 * */

export const SYSTEM_NOTIFICATION_IMMIEXPERT_KPMG_USER_ROLE = 'immi_kpmg_user';
export const SYSTEM_NOTIFICATION_IMMIEXPERT_CLIENT_END_USER_ROLE = 'immi_client_user';
export const SYSTEM_NOTIFICATION_SERVICE_NAME_IMMIEXPERT = 'immiExpert';

export const SYSTEM_NOTIFICATION_READ_NOTIFICATIONS_STORAGE_KEY = 'readNotifications';
