import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, Event, ResolveEnd } from '@angular/router';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { ProjectService } from '../../shared/services/project.service';
import { ProjectJSON } from 'access-common';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit, OnDestroy {
  private isDestroyed = new Subject();

  project$: Observable<ProjectJSON>;
  isImmigrationProject: boolean;
  showAccessTabs: boolean;
  showImmigrationTabs: boolean;

  constructor(
    private projectService: ProjectService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof ResolveEnd) {
        this.showImmigrationTabs = true;
//          _.filter(event.state.root.children[0].url, { path: 'immigration' })
//            .length > 0;
      }
    });
    this.project$ = this.projectService.getSelectedProject().pipe(
      tap(p => {
        this.isImmigrationProject =
          p && p.name && p.name.toLowerCase() === 'immigration expert';
      })
    );
  }

  ngOnDestroy() {
    this.isDestroyed.next();
  }

  launchImmigrationExpert(projectId) {
    window.open(`projects/` + projectId + `/verokone`, `_blank`);
  }
}
