import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {fadeIn} from '../../animations/animations';


@Component({
  selector: 'app-system-notification',
  templateUrl: './system-notification.component.html',
  styleUrls: ['./system-notification.component.scss'],
  animations: [fadeIn]
})

export class SystemNotificationComponent implements OnInit {
  notificationTitle: string;
  notificationContent: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<SystemNotificationComponent>) { }

  ngOnInit() {
    this.notificationTitle =  this.data.title;
    this.notificationContent = this.data.content;
  }

  close() {
    this.dialogRef.close();
  }
}
