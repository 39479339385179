/**
 * Check if Package is still valid
 *
 * @param {string} date
 * @returns {boolean}
 */
 export function isPackageValid(date: string): boolean {
  if (Date.parse(date) < Date.now()) {
    return false;
}
return true;
}
