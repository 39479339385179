import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { ImmigrationCountry } from '../../shared/models/ImmigrationCountry';

@Component({
  selector: 'app-immigration-input',
  templateUrl: './immigration-input.component.html',
  styleUrls: ['./immigration-input.component.css']
})
export class ImmigrationInputComponent implements OnInit {
  // INPUT: Key
  private _key = new BehaviorSubject<String>(null);
  @Input()
  set key(value) {
    this._key.next(value);
  }
  get key() {
    return this._key.getValue();
  }

  // INPUT: Data
  private _data = new BehaviorSubject<any[]>([]);
  @Input()
  set data(value) {
    this._data.next(value);
  }
  get data() {
    return this._data.getValue();
  }

  // INPUT: Label
  private _label = new BehaviorSubject<String>(null);
  @Input()
  set label(value) {
    this._label.next(value);
  }
  get label() {
    return this._label.getValue();
  }

  // INPUT: Input data
  private _inputData = new BehaviorSubject<ImmigrationCountry>(null);
  @Input()
  set inputData(value) {
    if (value == null) {
      value = { name: '', status: 'INACTIVE' };
    }
    this._inputData.next(value);
  }
  get inputData() {
    return this._inputData.getValue();
  }

  // INPUT: Value is selected
  private _valueSelected = new BehaviorSubject<Boolean>(null);
  @Input()
  set valueSelected(value) {
    this._valueSelected.next(value);
  }
  get valueSelected() {
    return this._valueSelected.getValue();
  }

  // INPUT: Extra questions
  private _eq = new BehaviorSubject<Boolean>(false);
  @Input()
  set eq(value) {
    this._eq.next(value);
  }
  get eq() {
    return this._eq.getValue();
  }

  // INPUT: Not found message
  private _notFoundMsg = new BehaviorSubject<String>(null);
  @Input()
  set notFoundMsg(msg) {
    this._notFoundMsg.next(msg);
  }
  get notFoundMsg() {
    return this._notFoundMsg.getValue();
  }

  // INPUT: Not active message
  private _notActiveMsg = new BehaviorSubject<String>(null);
  @Input()
  set notActiveMsg(msg) {
    this._notActiveMsg.next(msg);
  }
  get notActiveMsg() {
    return this._notActiveMsg.getValue();
  }

  // OUTPUT: Change event
  @Output()
  inputValueChanged: EventEmitter<any> = new EventEmitter<any>();

  // OUTPUT: URL params update
  @Output()
  URLparamUpdate: EventEmitter<any> = new EventEmitter<any>();


  // Public vars
  public results: ImmigrationCountry[];
  public hideList = false;
  public autocompleteItem = '';
  public typed = '';
  public allCountriesVisible = false;
  public selectedResIndex = 0;
  public inputFocused = false;
  public ie = false;
  public onHoldMsg = 'Country in being updated. Please contact <a href="mailto:immigrationexpert@kpmg.fi">immigrationexpert@kpmg.fi</a> for travel information.';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const ua = window.navigator.userAgent;
    if (
      ua ===
      'Mozilla/5.0 (Windows NT 6.1; Trident/7.0; SLCC2; .NET CLR 2.0.50727; .NET CLR 3.5.30729; .NET CLR 3.0.30729; Media Center PC 6.0; .NET4.0C; .NET4.0E; rv:11.0) like Gecko'
    ) {
      this.ie = true;
    }
  }

  filterData(event?: KeyboardEvent) {
    if (event && event.code === 'Tab') {
      // Don't process tab here
      return;
    }

    if (!this.inputData || !this.inputData.name) {
      this.typed = '';
      this.autocompleteItem = '';
      return;
    }
    this._valueSelected.next(false);
    this.URLparamUpdate.emit({key: this.key, value: undefined});
    this.hideList = false;

    // Transform to uppercase to make autocomplete look more seamless
    if (this.inputData.name.length === 1) {
      this.inputData.name = this.inputData.name.charAt(0).toUpperCase();
    }

    this.results = [];
    const secondaryResults = [];
    const inputLc = JSON.parse(JSON.stringify(this.inputData.name)).toLowerCase();
    const dataC = JSON.parse(JSON.stringify(this.data));

    dataC.forEach(d => {
      const dLc = d.name.toLowerCase();
      if (dLc === inputLc) {
        /* If exactly same */
        this.results.push(d);
      } else if (inputLc === dLc.slice(0, inputLc.length)) {
        /* If contains same characters starting from the beginning */
        this.results.push(d);
      } else {
        const containsWhitespaces = dLc.indexOf(' ') > -1;
        if (containsWhitespaces) {
          for (let i = 0; i < dLc.length; i++) {
            /* If contains same characters after a whitespace */
            if (
              inputLc.length > 1 &&
              dLc[i] === ' ' &&
              dLc.slice(i + 1, i + 1 + inputLc.length) === inputLc
            ) {
              secondaryResults.push(d);
            }
          }
        }
      }
    });

    if (secondaryResults.length > 0) {
      this.results = this.results.concat(secondaryResults);
    }

    // Autocomplete feature
    if (this.results.length > 0) {
      // Typed is a hidden variable in HTML to keep autocomplete consistent
      this.typed = this.results[0].name.substring(
        0,
        this.inputData.name.length
      );
      if (
        this.inputData.name.substring(0, 1).toLowerCase() ===
          this.results[0].name.substring(0, 1).toLowerCase() &&
        this.inputData.name.toLowerCase() ===
          this.results[0].name
            .substring(0, this.inputData.name.length)
            .toLowerCase()
      ) {
        this.autocompleteItem = this.results[0].name.substring(
          this.inputData.name.length,
          this.results[0].name.length
        );
      } else {
        this.autocompleteItem = '';
      }
    }
  }

  setFocus() {
    this.inputFocused = true;
  }

  selectNext() {
    if (this.data && this.selectedResIndex < this.results.length - 1) {
      ++this.selectedResIndex;
    }
  }

  selectPrevious() {
    if (this.data && this.selectedResIndex > 0) {
      --this.selectedResIndex;
    }
  }

  onEnter() {
    if (!this.results || this.results.length < 1) {
      return;
    }

    this.changeInputValue();
  }

  onTab(event) {
    if (event.code !== 'Tab') {
      return;
    }

    this.filterData();

    if (!this.results || this.results.length < 1) {
      event.preventDefault();
      return;
    }

    this.changeInputValue();
  }

  onBlur() {
    this.selectedResIndex = 0;
    this.inputFocused = false;
  }

  changeInputValue() {
    if (this.inputData.name.length > 0 && this.results.length > 0) {
      this.inputData = this.results[this.selectedResIndex];
      this.hideList = true;
      const userSelection = {
        label: this.label,
        name: this.results[this.selectedResIndex].name,
        value: this.results[this.selectedResIndex].value
      };
      this.inputValueChanged.emit(userSelection);
    }
  }

  deselect() {
    this.inputData = undefined;
    const userSelection = {
      label: this.label,
      name: undefined,
      value: undefined
    };
    this.inputValueChanged.emit(userSelection);
  }

  setCountryOfResidence(c: ImmigrationCountry) {
    this.hideList = true;
    this.inputData = JSON.parse(JSON.stringify(c));
    const userSelection = {
      label: this.label,
      name: c.name,
      value: c.value,
      locale: c.locale
    };

    this.inputValueChanged.emit(userSelection);
  }

  setCountryOfResidenceByIndex(i: number) {
    this.hideList = true;
    this.inputData = this.results[i];
    const userSelection = {
      label: this.label,
      name: this.results[i].name,
      value: this.results[i].value,
      locale: this.results[i].locale
    };

    this.inputValueChanged.emit(userSelection);
  }

  showAllCountries() {
    this.allCountriesVisible = !this.allCountriesVisible;
    this.cdr.markForCheck();
  }
}
