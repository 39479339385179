import {AuthService} from 'access-common';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {of as _of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {NotificationModel} from '../shared/models/notification.model';
import {NotificationService} from '../shared/services/notification.service';
import {HttpErrorResponse} from '@angular/common/http/src/response';
import {HttpClient} from '@angular/common/http';
import {Configuration} from '../shared/services/configuration';
import {ConfigurationKeys} from '../shared/constants';
import {SessionService} from "../shared/services/session.service";

@Injectable()
export class ImmigrationTosGuard implements CanActivate {
  stateUrl: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
    private httpClient: HttpClient,
    private configuration: Configuration,
    private sessionService: SessionService
  ) {}

  getAcceptedTos() {
    const basePath = this.configuration.getConfig(
      ConfigurationKeys.IMMIGRATION_API_BASE_PATH
    );
    const url = `${basePath}/acceptedTerms`;
    return this.httpClient.get<object>(url);
  }

  getCurrentTosFileName() {
    return this.httpClient.get('assets/immigrationTos.json');
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.sessionService.getSessionUser()
      .pipe(
        switchMap(user => {
          return this.getCurrentTosFileName().pipe(
            switchMap((file: object) => {
              const currentTosFileName = file['filename'];
              if (!currentTosFileName) {
                return;
              }
              return this.getAcceptedTos().pipe(
                switchMap((acceptedTos: object) => {
                  const acceptedTosName = acceptedTos['acceptedTermsOfService'];
                  if (
                    acceptedTosName &&
                    acceptedTosName === currentTosFileName
                  ) {
                    return _of(true);
                  } else {
                    this.authService.storeTargetRoute(this.stateUrl);
                    const id = localStorage.getItem('selectedProject').replace(/"/g, '');
                    this.router
                      .navigate([id, 'tos'])
                      .then(success => {});
                    return _of(false);
                  }
                })
              );
            })
          );
        })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.authService.storeTargetRoute(state.url);
          if (error && error.status === 404) {
            const id = localStorage.getItem('selectedProject').replace(/"/g, '');
            this.router.navigate([id, 'tos']);
            return _of(false);
          } else {
            const notificationSettings: NotificationModel = {
              config: {
                level: 'error',
                msg:
                  'Error occurred while loading user info. Please try loading page again',
                isPopup: false
              }
            };
            this.notificationService.notify(notificationSettings);
            this.router.navigateByUrl('/');
            return _of(false);
          }
        })
      );
  }
}
