import * as moment from 'moment';

/**
 * Parse from timestamp to DD/MM/YYYY (ex: 05/07/2020)
 *
 * @param {string} date
 * @returns {string}
 */
export function parseDateToDDMMYYYY(date: string) : string {
  return date ? moment(date).format("DD/MM/YYYY") : null;
}

/**
 * Parse from timestamp to DD MMMM YYYY (ex: 23 May 2021)
 *
 * @param {string} date
 * @returns {string}
 */
export function parseDateToDDMMMMYYYY(date: string): string {
  return date ? moment(new Date(date)).format("DD MMMM YYYY") : null;
}