import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { tap, switchMap } from 'rxjs/internal/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ImmigrationCountry } from '../models/ImmigrationCountry';
import { Configuration } from './configuration';
import { ConfigurationKeys } from '../constants';
import { Observable, of } from 'rxjs';
import { ResultSectionJSON } from '../models/result-section';
import { SummaryResult } from '../models/summary-result';
import { NextQuestionResponse } from '../models/next-question-response';
import { StateWithQuestion } from '../../components/immigration-extra-questions/immigration-extra-questions.component';
import { ComparisonResultJSON } from '../models/ImmigrationComparison';

@Injectable()
export class ImmigrationQuestionService {
  summaryVisited = new BehaviorSubject<Boolean>(false);

  allCountries: ImmigrationCountry[] = [];
  _question: BehaviorSubject<NextQuestionResponse> = new BehaviorSubject<
    NextQuestionResponse>(null);
  _answer: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private id = JSON.parse(localStorage.getItem('selectedProject'));
  private companyId = JSON.parse(localStorage.getItem('selectedCompany'));

  constructor(private http: HttpClient, private configuration: Configuration) {}

  nextSection(from, to, payload): Observable<StateWithQuestion> {
    const basePath = this.configuration.getConfig(
      ConfigurationKeys.IMMIGRATION_API_BASE_PATH
    );
    const url = `${basePath}/question/${from}/${to}/next`;
    let param = new HttpParams();
    if (this.companyId) {
      param = param.append('companyId', this.companyId);
    }
    return this.http
      .post<NextQuestionResponse>(url, payload, {
        params: param
      })
      .pipe(
        tap(res => this._question.next(res)),
        switchMap(res => of({ state: payload, result: res }))
      );
  }

  getSummaryObj(object: any): Observable<SummaryResult[]> {
    if (!(object['CITIZENSHIP'] && object['DESTINATION'])) {
      throw new Error('Citizenship and/or destination missing');
    }

    const payload = Object.keys(object).map(key => {
      return {
        key,
        value: object[key]
      };
    });

    return this.getSummary(object['CITIZENSHIP'], object['DESTINATION'], payload);
  }

  getSummary(from: string, to: string, query: any): Observable<SummaryResult[]> {
    const basePath = this.configuration.getConfig(
      ConfigurationKeys.IMMIGRATION_API_BASE_PATH
    );
    const url = `${basePath}/question/${encodeURIComponent(from)}/${encodeURIComponent(to)}/summary`;
    let param = new HttpParams();
    if (this.companyId) {
      param = param.append('companyId', this.companyId);
    }
    return this.http.post<SummaryResult[]>(url, query, {
      params: param
    });
  }

  setAnswer(a) {
    this._answer.next(a);
  }

  fetchResult(): Observable<ComparisonResultJSON> {
    const basePath = this.configuration.getConfig(
      ConfigurationKeys.IMMIGRATION_API_BASE_PATH
    );
    const payload = this._answer.getValue();
    const url = `${basePath}/question/${encodeURIComponent(payload['CITIZENSHIP'])}/${encodeURIComponent(payload['DESTINATION'])}/answer`;
    let param = new HttpParams();
    if (this.companyId) {
      param = param.append('companyId', this.companyId);
    }

    return this.http.post<ComparisonResultJSON>(url, Object.keys(payload).map(key => {
      return {
        key,
        value: payload[key]
      };
    }), {headers: new HttpHeaders({projectId: this.id}), params: param});
  }

  fetchComparison(searchId1, searchId2): Observable<any> {
    const basePath = this.configuration.getConfig(
      ConfigurationKeys.IMMIGRATION_API_BASE_PATH
    );

    const url = `${basePath}/question/${encodeURIComponent(searchId1)}/${encodeURIComponent(searchId2)}/compare`;
    let param = new HttpParams();
    if (this.companyId) {
      param = param.append('companyId', this.companyId);
    }

    return this.http.get<ResultSectionJSON[]>(url, {headers: new HttpHeaders({projectId: this.id}), params: param});
  }
}
