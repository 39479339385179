import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, Params /*, ActivatedRoute */ } from '@angular/router';
import { matches as _matches, filter as _filter } from 'lodash';
import { ImmigrationUserRightsDto } from '../../shared/models/immigrationUserRights';
import { ImmigrationUserRightsService } from '../../shared/services/immigrationUserRights.service';

@Component({
  selector: 'app-immigration-tabs',
  templateUrl: './immigration-tabs.component.html',
  styleUrls: ['./immigration-tabs.component.css']
})
export class ImmigrationTabsComponent implements OnInit {
  projectId: string;

  public immigrationUserRights: ImmigrationUserRightsDto;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private userRightsService: ImmigrationUserRightsService // private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    /*
    this.activatedRoute.snapshot.parent.params.subscribe((params: Params) => {
      this.projectId = params['projectId'];
    });
    */
    this.userRightsService.getUserRights().subscribe(
      userRights => {
        this.immigrationUserRights = userRights;
      },
      error => {}
    );
    this.projectId = JSON.parse(localStorage.getItem('selectedProject'));
  }
}
