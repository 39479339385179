import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';
import { SearchService } from '../../shared/services/search.service';
import { ImmigrationQuestionService } from '../../shared/services/ImmigrationQuestion.service';
import { b64EncodeUnicode } from '../../shared/base64';
import { NotificationModel } from '../../shared/models/notification.model';
import { ComparisonResultJSON } from '../../shared/models/ImmigrationComparison';
import { cloneDeep } from 'lodash';
import { SectionJSON } from '../../shared/models/section';
import { ResultSectionJSON } from '../../shared/models/result-section';
import * as _ from 'lodash';
import { ImmigrationLatestSearch } from '../../shared/models/ImmigrationLatestSearch';
import { animateIn, animateOut } from '../../shared/animations/animations';
import { getSearchedAtValue } from '../../shared/utils/get-search-time-string';
import { navigateHome } from '../../shared/utils/navigate-home';
import { convertSearchParamsResponseToQuery } from '../../shared/utils/convert-response-to-params';

@Component({
  selector: 'app-immigration-comparison',
  templateUrl: './immigration-comparison.component.html',
  styleUrls: ['./immigration-comparison.component.scss'],
  animations: [
    animateIn,
    animateOut
  ]
})
export class ImmigrationComparisonComponent implements OnInit {
  public queryParams = [];
  public latestSearchesCount = 5;
  public latestSearches: Array<ImmigrationLatestSearch> = [];
  public searchesSelectedToCompare: Array<ImmigrationLatestSearch> = [];
  private selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  lsCardPlaceholders: Array<any> = [];
  answers: Array<ComparisonResultJSON> = [];
  improvedAnswers: Array<ResultSectionJSON> = [];
  isLatestSearchesPanelHidden: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifyService: NotificationService,
    private immigrationQuestionService: ImmigrationQuestionService,
    private searchService: SearchService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.queryParams = params.id;
      this.getLatestSearches().subscribe();
      this.fetchResult();
    });
  }

  getLatestSearches() {
    return this.searchService.getLatestSearches(this.latestSearchesCount, this.selectedProject)
      .pipe(
        tap(latestSearches => {
          latestSearches.map(search => {
            search['searchedAtStr'] = getSearchedAtValue(search.searchedAt).fromNowStr;
          });
          this.latestSearches = latestSearches;
          this.searchesSelectedToCompare = latestSearches.filter(v => this.queryParams.includes(v.id.toString()));
          if (latestSearches.length < this.latestSearchesCount) {
            const arrayLength = this.latestSearchesCount - latestSearches.length;
            this.lsCardPlaceholders = Array(arrayLength).fill('');
          }
        })
      );
  }

  fetchResult() {
    this.immigrationQuestionService.fetchComparison(this.queryParams[0], this.queryParams[1])
      .subscribe(res => {
        this.answers = res;
        this.improvedAnswers = this.convertResponseToSections(res);
      }, () => {
        this.notifyService.notify(
          new NotificationModel({
            level: 'error',
            isPopup: false,
            autoClose: true,
            msg: 'Error happened when fetching comparison result.'
          })
        );
      });
  }

  convertResponseToSections(response: Array<ComparisonResultJSON>): ResultSectionJSON[] {
    const result: ResultSectionJSON[] = [];
    response.forEach((res , currentResponseIndex) => {
      res.answer.forEach(newSection => {
        const existingSectionIndex = result.findIndex(existingSection => existingSection.displayName === newSection.displayName);
        const resultHasSectionForThisResponse = existingSectionIndex > -1;
        if (resultHasSectionForThisResponse) {
          const existingSubsectionsArray = result[existingSectionIndex].elements;
          const currentSubsectionsArray = newSection.elements;
          result[existingSectionIndex].elements = this.addSubsectionsToSection(existingSubsectionsArray, currentSubsectionsArray, response);
        } else {
          result.push(this.addNewSectionToResult(currentResponseIndex, newSection, response));
        }
      });
    });
    return _.orderBy(result, 'section', 'desc');
  }

  addSubsectionsToSection(currentSectionArray: SectionJSON[], newSection: SectionJSON[], response: ComparisonResultJSON[]) {
    // Clone current section array to modify and use the original array for iteration
    const sectionResult = cloneDeep(currentSectionArray);
    const currentSectionId = response[0].id;
    const newSectionId = response[1].id;
    newSection.forEach(newSubsection => {
      const currentSubsectionIndex = sectionResult.findIndex(subsection => subsection.displayName === newSubsection.displayName);
      const currentSectionHasThisSubsection = currentSubsectionIndex > -1;
      if (currentSectionHasThisSubsection) {
        // If previous answer already has this subsection, add this answer's subsection after it
        const indexOfNewSubsection = currentSubsectionIndex + 1;
        sectionResult.splice(indexOfNewSubsection, 0, { id: newSectionId, ...newSubsection });
      } else {
        // If previous answer does not have this subsection, push this answer and an empty value for previous answer
        sectionResult.push({
          id: currentSectionId,
          displayName: newSubsection.displayName,
          icon: newSubsection.icon,
          value: ''
        });
        sectionResult.push({ id: newSectionId, ...newSubsection });
      }
    });
    // Check if current section has subsection that this answer does not have and push empty value next to it
    currentSectionArray.forEach((currentSubsection) => {
      if (!newSection.some(newSubsection => newSubsection.displayName === currentSubsection.displayName)) {
        const currentSubsectionIndex = sectionResult.findIndex(subsection => subsection.displayName === currentSubsection.displayName);
        const newSubsectionIndex = currentSubsectionIndex + 1;
        const itemToPush = {
          id: newSectionId,
          displayName: currentSubsection.displayName,
          icon: currentSubsection.icon,
          value: ''
        };
        sectionResult.splice(newSubsectionIndex, 0, itemToPush);
      }
    });
    return sectionResult;
  }

  addFillValuesToSubsectionsArray(firstAnswerSubsectionObj, secondAnswerSubsectionObj, response, subsectionArray) {
    if (Object.keys(firstAnswerSubsectionObj).length === 0) {
      subsectionArray.push({
        id: response[0].id,
        displayName: secondAnswerSubsectionObj.displayName,
        icon: secondAnswerSubsectionObj.icon,
        value: ''
      });
      subsectionArray.push({id: response[1].id, ...secondAnswerSubsectionObj });
    } else {
      subsectionArray.push({id: response[0].id, ...firstAnswerSubsectionObj});
      subsectionArray.push({
        id: response[1].id,
        displayName: firstAnswerSubsectionObj.displayName,
        icon: firstAnswerSubsectionObj.icon,
        value: ''
      });
    }
  }

  addNewSectionToResult(currentResponseIndex, newSection: ResultSectionJSON, response: ComparisonResultJSON[]) {
    const subsectionsResult: SectionJSON[] = [];
    const newSubsections = newSection.elements;
    if (currentResponseIndex === 1) {
      // if index === 1 meaning previous response does not have this section
      // push empty value for each subsection
      newSubsections.forEach(subsection => {
        this.addFillValuesToSubsectionsArray({}, subsection, response, subsectionsResult);
      });
    } else {
      // if this is the first response, check if the section exists in second response
      // if not, create placeholders for each subsection
      const currentAnswerId = response[0].id;
      const nextResponseSubsections = response[1].answer;
      if (!nextResponseSubsections.some(subsection => subsection.displayName === newSection.displayName)) {
        newSubsections.forEach(subsection => {
          this.addFillValuesToSubsectionsArray(subsection, {}, response, subsectionsResult);
        });
      } else {
        newSubsections.forEach(subsection => {
          subsectionsResult.push({id: currentAnswerId, ...subsection });
        });
      }
    }
    return {
      displayName: newSection.displayName,
      elements: subsectionsResult
    };
  }

  selectForCompare(search) {
    if (this.isCompareSelectionEnabled(search)) {
      this.searchesSelectedToCompare.push(search);
    }
  }

  isCompareSelectionEnabled(search): boolean {
    return this.searchesSelectedToCompare.length < 2 && !this.searchesSelectedToCompare.some(item => item.id === search.id);
  }

  removeFromCompare(search) {
    this.searchesSelectedToCompare = this.searchesSelectedToCompare.filter(item => item.id !== search.id);
  }

  copySearchToNew(search) {
    let queryParamsToSearch;
    this.searchService.getSearchParameters(search.id).subscribe(params => {
      queryParamsToSearch = convertSearchParamsResponseToQuery(params);
      const state = b64EncodeUnicode(JSON.stringify(queryParamsToSearch));
      navigateHome(queryParamsToSearch, state, this.router);
    });
  }

  showComparison() {
    const pathParams = [];
    this.searchesSelectedToCompare.forEach(s => pathParams.push(s.id));
    this.router.navigate(['../comparison'], {
      relativeTo: this.route,
      queryParams: { id: pathParams }
    });
  }

  toggleLatestSearchesPanelVisibility() {
    this.isLatestSearchesPanelHidden = !this.isLatestSearchesPanelHidden;
  }

  modifySearch(answer) {
    this.copySearchToNew(answer);
  }

  newSearch() {
    navigateHome({}, null, this.router);
  }
}
