import {animate, style, transition, trigger} from '@angular/animations';

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({opacity: 0}),
    animate('225ms', style({opacity: 1}))
  ])
]);

export const animateIn = trigger('animateIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      localStorage.getItem('searchLoaded') === '1' ? '250ms' : '750ms',
      style({ opacity: 1 })
    )
  ])
]);

export const animateOut = trigger('animateOut', [
  transition(':leave', [
    style({ opacity: 1 }),
    animate('225ms', style({ opacity: 0 }))
  ])
]);
