export class BreadcrumbsNode {
    label: string;
    type: string;
    callback: () => any;

    /**
     * Label is displayed in UI, callback must be a function that can be executed
     */
    constructor(label: string, type: string, callback: () => any) {
        this.label = label;
        this.callback = callback;
        this.type = type;
    }
}
