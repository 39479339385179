import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { of as _of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ImmigrationUserRightsService } from '../services/immigrationUserRights.service';

@Injectable()
export class ImmigrationUserRightsGuard implements CanActivate {
  constructor(private router: Router, private userRightsService: ImmigrationUserRightsService) {}

  hasRightsForView(state, userRights) {
    if (state.url && state.url.indexOf('/search') > -1) {
      return userRights.searchView;
    } else if (state.url && state.url.indexOf('/country-rules') > -1) {
      return userRights.adminView;
    } else if (state.url && state.url.indexOf('/admin-view') > -1) {
      return userRights.adminView;
    } else if (state.url && state.url.indexOf('/statistics') > -1) {
      return userRights.statisticsView;
    } else if (state.url && state.url.indexOf('/tos') > -1) {
      return true;
    } else {
      return false;
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userRightsService.getUserRights().pipe(
      switchMap(userRights => {
        if (state && userRights && this.hasRightsForView(state, userRights)) {
          return _of(true);
        } else {
          const projectId = state.url.split('/')[2];
          this.router.navigate([projectId]);
          return _of(false);
        }
      })
    );
  }
}
