import { Injectable, Injector, InjectionToken } from '@angular/core';
import {
  OidcSecurityService,
  OpenIDImplicitFlowConfiguration,
  AuthWellKnownEndpoints
} from 'angular-auth-oidc-client';
import { ConfigurationKeys } from '../constants';
import { ConfigurationHolder } from '../../configuration.holder';

@Injectable()
export class Configuration {
  constructor(private injector: Injector) {}

  public getConfig(key: any) {
    if (
      ConfigurationHolder.configuration &&
      ConfigurationHolder.configuration.hasOwnProperty(key)
    ) {
      return ConfigurationHolder.configuration[key];
    } else {
      if (console) {
        console.log('Configuration not found for ' + key);
      }
      return null;
    }
  }

  /**
   * Load configuration
   */
  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.initOidcClient(resolve, reject);
    });
  }

  initOidcClient(resolve, reject) {
    const oidcService = this.injector.get(OidcSecurityService);
    const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
    const conf = '../../../config/well-know-conf.json';
    const wellKnownEndpoints = new AuthWellKnownEndpoints();
    wellKnownEndpoints.issuer = conf['issuer'];
    wellKnownEndpoints.authorization_endpoint = conf['authorization_endpoint'];
    wellKnownEndpoints.userinfo_endpoint = conf['userinfo_endpoint'];
    wellKnownEndpoints.token_endpoint = conf['token_endpoint'];
    wellKnownEndpoints.jwks_uri = conf['jwks_uri'];
    openIDImplicitFlowConfiguration.stsServer = this.getConfig(
      ConfigurationKeys.PORTAL_URL
    );
    openIDImplicitFlowConfiguration.redirect_url = this.getConfig(
      ConfigurationKeys.CALLBACK_URL
    );
    openIDImplicitFlowConfiguration.client_id = this.getConfig(
      ConfigurationKeys.CLIENT_ID
    );
    openIDImplicitFlowConfiguration.response_type = 'id_token token';
    openIDImplicitFlowConfiguration.scope = 'openid';
    openIDImplicitFlowConfiguration.post_logout_redirect_uri =
      window.location.origin + '/unauthorized';
    openIDImplicitFlowConfiguration.post_login_route = '/auth';
    openIDImplicitFlowConfiguration.forbidden_route = '/forbidden';
    openIDImplicitFlowConfiguration.unauthorized_route = '/unauthorized';
    openIDImplicitFlowConfiguration.log_console_warning_active = false;
    openIDImplicitFlowConfiguration.log_console_debug_active =
      this.getConfig(ConfigurationKeys.OIDC_DEBUG) === 'true' || false;
    openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 60;
    openIDImplicitFlowConfiguration.trigger_authorization_result_event = true;
    openIDImplicitFlowConfiguration.storage = localStorage;
    openIDImplicitFlowConfiguration.silent_renew = false;
    oidcService.setupModule(
      openIDImplicitFlowConfiguration,
      wellKnownEndpoints
    );
    oidcService.onModuleSetup.subscribe(
      () => resolve(),
      error => {
        // console.error(error);
        reject();
      }
    );
  }
}
export const SYSTEM_NOTIFICATION_URL = new InjectionToken<string>(ConfigurationKeys.SYSTEM_NOTIFICATION_URL);
