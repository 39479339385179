import { of as observableOf, BehaviorSubject, Observable, Subject } from 'rxjs';

import { refCount, publishLast, switchMap, first } from 'rxjs/operators';
import {
  CompanyJSON,
  Session as SessionService,
  ProjectService as ProjectApi,
  FileJSON as File,
  ProjectJSON as Project,
} from 'access-common';
import { Injectable } from '@angular/core';

@Injectable()
export class ProjectService {
  private projectListChangedSource = new Subject<void>();
  projectListChanged$ = this.projectListChangedSource.asObservable();

  private subject = new BehaviorSubject<Project>(undefined);
  private selectedProject$: Observable<Project> = this.subject.asObservable();

  constructor(
    private api: ProjectApi,
    private sessionService: SessionService,
  ) {}

  notifyProjectListChange() {
    this.projectListChangedSource.next();
  }

  getProject(uuid: string): Observable<Project> {
    return this.api.getProjectByIdUsingGET(uuid, true);
  }

  getProjects(companyId: string, includeUnread: boolean): Observable<Project[]> {
    return this.api.getProjectListForCompanyUsingGET(companyId, includeUnread);
  }

  getSelectedProject(): Observable<Project> {
    return this.selectedProject$;
  }

  deselectProject(): void {
    localStorage.removeItem('selectedProject');
    this.subject.next(undefined);
  }

  selectProject(project: Project) {
    localStorage.setItem('selectedProject', JSON.stringify(project.id));
    this.subject.next(project);
  }


  search(
    companyId?: string,
    searchString?: string,
    attrName?: string,
    attrValue?: string
  ): Observable<Project[]> {
    // TODO:
    return observableOf([]);
  }
}
