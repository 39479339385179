import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-immigration-progress',
  templateUrl: './immigration-progress.component.html',
  styleUrls: ['./immigration-progress.component.css']
})
export class ImmigrationProgressComponent implements OnInit {
  questionsPageVisibility: boolean;
  summaryPageVisibility: boolean;
  linksActive: Array<string>;
  private projectId: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.linksActive = [''];
    router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (
          !event.url.includes('extra-questions') &&
          !event.url.includes('summary')
        ) {
          this.questionsPageVisibility = false;
          this.summaryPageVisibility = false;
          this.linksActive = [''];
        }

        if (
          event.url.includes('extra-questions') &&
          !event.url.includes('summary')
        ) {
          this.questionsPageVisibility = true;
          this.summaryPageVisibility = false;
          this.linksActive = ['', 'extra-questions'];
        }

        if (
          !event.url.includes('extra-questions') &&
          event.url.includes('summary')
        ) {
          this.questionsPageVisibility = false;
          this.summaryPageVisibility = true;
          this.linksActive = ['', 'extra-questions', 'summary'];
        }
      });
    this.projectId = JSON.parse(localStorage.getItem('selectedProject'));
  }

  goToPage(pageName) {
    if (this.linksActive.indexOf(pageName) > -1) {
      this.route.queryParams.subscribe(params =>
        this.router.navigate(
          [`${this.projectId}/search/${pageName}`],
          { queryParams: params }
        )
      );
    }
  }

  ngOnInit() {
    if (
      !this.router.url.includes('extra-questions') &&
      !this.router.url.includes('summary')
    ) {
      this.questionsPageVisibility = false;
      this.summaryPageVisibility = false;
    }

    if (
      this.router.url.includes('extra-questions') &&
      !this.router.url.includes('summary')
    ) {
      this.questionsPageVisibility = true;
      this.summaryPageVisibility = false;
    }

    if (
      !this.router.url.includes('extra-questions') &&
      this.router.url.includes('summary')
    ) {
      this.questionsPageVisibility = false;
      this.summaryPageVisibility = true;
    }
  }
}
