import { Observable } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsNode } from '../../shared/models/breadcrumbs.node';
import { map } from 'rxjs/operators';
import { Subject, of as observableOf } from 'rxjs';
import { ProjectService } from '../../shared/services/project.service';
import { CompanyService } from '../../shared/services/company.service';
import { CustomershipService } from '../../shared/services/customership.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private isDestroyed = new Subject();

  breadcrumbs: Observable<BreadcrumbsNode>[] = [];
  selectedProject;

  constructor(
    private customershipService: CustomershipService,
    private companyService: CompanyService,
    private projectService: ProjectService,
  ) {}

  ngOnInit() {
    this.breadcrumbs = [
      observableOf(new BreadcrumbsNode('KPMG Move', 'link', () => {
          this.customershipService.deselectCustomership();
          this.companyService.deselectCompany();
          this.projectService.deselectProject();

          const port = window.location.port ? `:${window.location.port}` : '';
          const basePath = `${window.location.protocol}//${window.location.hostname}${port}`;
          window.location.replace(`${basePath}`);
        })),
      this.companyService.getSelectedCompany().pipe(map(company => {
        if (company) {
          return new BreadcrumbsNode(company.companyname, 'link', () => {
            this.projectService.deselectProject();
            const port = window.location.port ? `:${window.location.port}` : '';
            const basePath = `${window.location.protocol}//${window.location.hostname}${port}`;
            window.location.replace(`${basePath}/companies/${company.id}`);
          });
        } else { return undefined; }
      })),
      this.projectService.getSelectedProject().pipe(
        map(project => {
          if (project) {
            this.selectedProject = project;
            return new BreadcrumbsNode(project.name, 'dropdown', () => {
              return this.projectService
                .getProjects(project.companyId, false)
                .pipe(
                  map(projects =>
                    projects.map(
                      p =>
                        new BreadcrumbsNode(p.name, 'link', () => {
                          this.projectService.selectProject(p);
                          const port = window.location.port ? `:${window.location.port}` : '';
                          const basePath = `${window.location.protocol}//${window.location.hostname}${port}`;
                          window.location.replace(`${basePath}/projects/${p.id}`);
                        })
                    )
                  )
                );
            });
          } else {
            return undefined;
          }
        })
      )
    ];
  }

  ngOnDestroy() {
    this.isDestroyed.next();
  }
}
