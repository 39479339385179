import { ImmigrationCompanySearchRequest } from "../models/ImmigrationCompany";

/**
 * Find the most recently added search request
 *
 * @param {ImmigrationCompanySearchRequest[]} searchRequests
 * @returns {string}
 */
 export function findExpirationDate(searchRequests: ImmigrationCompanySearchRequest[]): string {
  return searchRequests.length > 0 ? searchRequests[0].validityPeriod : null;
}