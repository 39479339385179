import * as moment from 'moment';

/**
 * Get searched at value from timestamp
 *
 * @param {string} timestamp
 */
export function getSearchedAtValue(timestamp: string) {
  const localTime = getLocalTime(timestamp);
  const fromNowStr = moment(localTime).fromNow();
  const fromNowInSecs = moment().diff(moment(localTime), 'seconds');
  return {
    'LT': localTime,
    'fromNowStr': fromNowStr.charAt(0).toUpperCase() + fromNowStr.slice(1),
    'fromNowInSecs': fromNowInSecs
  };
}

function getLocalTime(UTCtime): moment.Moment {
  if (!UTCtime || UTCtime.length < 1) {
    return;
  }
  const UTCmoment = moment.utc(UTCtime);
  return moment(UTCmoment).local();
}
