import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Configuration } from './configuration';
import { ConfigurationKeys } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class FrontdoorService {
  basePath = this.configuration.getConfig(
    ConfigurationKeys.IMMIGRATION_API_BASE_PATH
  );
  constructor(private http: HttpClient, private configuration: Configuration) {}
  openFrontdoor(params: any) {
    const query = '?' + Object.keys(params).map(function(key) {
      return key + '=' + encodeURIComponent(params[key]);
    }).join('&');
    const url = `${this.basePath}/frontdoor${query}`;
    return this.http.get<any>(url);
  }
}
