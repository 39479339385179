import {AuthService, UserJSON} from 'access-common';
import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {SessionUserJSON, ProjectJSON} from 'access-common';
import {Router} from '@angular/router';
import {ProjectService} from '../../shared/services/project.service';
import {ConfigurationKeys, SYSTEM_NOTIFICATION_READ_NOTIFICATIONS_STORAGE_KEY} from '../../shared/constants';
import {Configuration} from '../../shared/services/configuration';
import {SystemNotificationPollerService} from '../../shared/services/system-notification-poller.service';
import {Observable, Subscription} from 'rxjs';
import {LogoutService} from '../../shared/services/logout.service';
import {SessionService} from "../../shared/services/session.service";


@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.css']
})
export class TopNavigationComponent implements OnInit, OnDestroy {

  mobile = false;
  private _user: SessionUserJSON;
  private _project: ProjectJSON;
  private showUserTos = false;
  private notificationSubscription: Subscription;
  showNotificationIcon$: Observable<boolean>;

  get project(): ProjectJSON {
    return this._project;
  }


  @Input()
  set project(project: ProjectJSON) {
    this._project = project;
  }

  get user(): SessionUserJSON {
    return this._user;
  }

  @Input()
  set user(user: SessionUserJSON) {
    this._user = user;
    if (user) {
      this.showUserTos = user.personalTosApproved;
    }
  }

  constructor(private authService: AuthService,
              private router: Router,
              private projectService: ProjectService,
              private config: Configuration,
              private systemNotificationPoller: SystemNotificationPollerService,
              private logoutService: LogoutService,
              private sessionService: SessionService) {
  }

  ngOnInit() {
    if (this.config.getConfig(ConfigurationKeys.SYSTEM_NOTIFICATIONS_ENABLED) === 'true') {
      this.subscribeToSystemNotifications();
    }
    this.sessionService.getSessionUser().subscribe(user => {
      this.user = user;
    });
  }

  /**
   * This is used for the KPMG logo in mobile to allow navigating to the front page
   */
  home() {
    this.router.navigate(['']).then(() => {
      this.projectService.deselectProject();
    });
  }

  logout() {
    sessionStorage.removeItem(SYSTEM_NOTIFICATION_READ_NOTIFICATIONS_STORAGE_KEY);
    this.logoutService.endSession().subscribe(res => {
    });
  }

  ngOnDestroy(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

  /**
   * Subscribe to incoming new system notifications on init and subscribe to timer in poller
   */
  subscribeToSystemNotifications() {
    this.showNotificationIcon$ = this.systemNotificationPoller.getNotificationIconObservable();
    this.notificationSubscription = this.systemNotificationPoller.pollNewSystemNotificationsForRoles()
      .subscribe();
  }

  /**
   * We want to show existing system notifications via notification icon click
   */
  showSystemNotificationsOnClick() {
    this.systemNotificationPoller.showNotificationPopUp();
  }

}
