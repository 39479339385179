import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CustomershipService as CustomershipApi } from 'access-common';
import { CustomershipJSON } from 'access-common';

@Injectable()
export class CustomershipService {
  private subject = new BehaviorSubject<CustomershipJSON>(undefined);
  private selectedCustomership$ = this.subject.asObservable();

  constructor(
    private api: CustomershipApi,
  ) {}

  getCustomership(id: string, full?: boolean): Observable<CustomershipJSON> {
    return this.api.getCustomershipUsingGET(id, full);
  }

  selectCustomership(customership: CustomershipJSON) {
    localStorage.setItem(
      'selectedCustomership',
      JSON.stringify(customership.id)
    );
    this.subject.next(customership);
  }

  deselectCustomership() {
    localStorage.removeItem('selectedCustomership');
    this.subject.next(undefined);
  }

  getSelectedCustomership(): Observable<CustomershipJSON> {
    return this.selectedCustomership$;
  }
}
