import { Injectable } from '@angular/core';
import { ImmigrationGeneralModel } from '../models/ImmigrationGeneralModel';

@Injectable()
export class ImmigrationSpecsService {
  specs: ImmigrationGeneralModel[] = [];

  constructor() {}

  getSpecs() {
    return this.specs;
  }

  setSpecs(s: ImmigrationGeneralModel[]) {
    this.specs = s;
  }
}
