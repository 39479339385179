import { NotificationService } from '../../services/notification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationModel } from '../../models/notification.model';
import { MatDialog } from '@angular/material';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { Subscription } from 'rxjs/Subscription';
import { animateIn, animateOut } from '../../animations/animations';

@Component({
  selector: 'app-error',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  animations: [
    animateIn,
    animateOut
  ]
})
export class NotificationComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  notification: NotificationModel;

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  clearNotifications(time) {
    if (time) {
      setTimeout(
        function() {
          this.notificationService.clearNotifications();
        }.bind(this),
        typeof time === 'number' ? time : 5750
      );
    } else {
      this.notificationService.clearNotifications();
    }
  }

  ngOnInit() {
    this.subscription = this.notificationService.notification$.subscribe(
      notification => {
        if (notification && notification.config) {
          if (typeof notification.config.msg === 'string') {
            notification.config.msg = [notification.config.msg];
          }
          if (notification.config.isPopup) {
            this.dialog
              .open(NotificationPopupComponent, { data: notification })
              .afterClosed()
              .subscribe(result => {});
          } else {
            this.notification = notification;
            if (notification.config.autoClose) {
              this.clearNotifications(notification.config.autoClose);
            }
          }
        } else {
          this.notification = undefined;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeErrors() {
    this.notificationService.clearNotifications();
  }
}
